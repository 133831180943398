import { useVariant } from '@unleash/proxy-client-react';
import { useSelector } from 'react-redux';

import { FEATURE_PDP_ENHANCEMENTS } from 'constants/features';
import type { AppState } from 'types/app';
import { selectFeaturePdpEnhancements } from 'selectors/features';

export const useFeaturePdpEnhancements = () => {
  useVariant(FEATURE_PDP_ENHANCEMENTS);

  return useSelector((state: AppState) => selectFeaturePdpEnhancements(state));
};

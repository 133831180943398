import ExecutionEnvironment from 'exenv';

import type { ApiConfig, AppState } from 'types/app';
import { emptyArray, emptyObject } from 'selectors/constants';
import { selectIsFeatureMafiaAkamai } from 'selectors/features';
import marketplace from 'cfg/marketplace.json';

export const selectEnvironment = (state: AppState) => state.environmentConfig || emptyArray;

export const selectApiConfig = (state: AppState) => selectEnvironment(state).api || emptyObject;

export const selectCloudCatalogApi = (state: AppState) => selectEnvironment(state).api.cloudcatalog || emptyObject;

export const selectIsMockApi = (state: AppState) => selectEnvironment(state).isMockApi;

export const selectOpalApi = (state: AppState) => selectEnvironment(state).api.opal || {};

export const selectCalypsoConfig = (state: AppState): ApiConfig => {
  const calypsoConfig = selectApiConfig(state).calypso || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(calypsoConfig).length) {
    return { ...calypsoConfig, url: marketplace.api.calypso.url };
  }

  const envCookie = state?.environmentConfig?.env || {};

  const hasEnvCookie = Boolean(envCookie?.calypso);
  // dont use akamai in dev or non ZFC paths, prefer direct in server side ops.
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.hostname?.includes('marty')
      ? `https://${window.location.hostname}/mobileapi/olympus`
      : calypsoConfig.url;

  // do not set the base URL to akamai if the env cookie is set.
  const url = hasEnvCookie && calypsoConfig.url ? calypsoConfig.url : akamaiOrDirectUrl;

  return { ...calypsoConfig, url };
};

export const selectMafiaConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const mafiaConfig = selectApiConfig(state).mafia || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(mafiaConfig).length) {
    return { ...mafiaConfig, url: marketplace.api.mafia.url };
  }

  return { ...mafiaConfig, url: isFeatureMafiaAkamai ? mafiaConfig.akamaiUrl : mafiaConfig.url };
};

export const selectZcsConfig = (state: AppState) => {
  const zcsConfig = selectApiConfig(state).zcs || emptyObject;
  const envCookie = state?.environmentConfig?.env || {};

  // Fallback if state values are not available
  if (!Object.keys(zcsConfig).length) {
    return { ...zcsConfig, url: marketplace.api.zcs.url };
  }

  // dont use akamai in dev or non ZFC paths, prefer direct in server side ops.
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.hostname?.includes('marty')
      ? `https://${window.location.hostname}/mobileapi/zcsd`
      : zcsConfig.directUrl;
  return { ...zcsConfig, url: envCookie.zcs ? zcsConfig.url : akamaiOrDirectUrl };
};

export const selectJanusConfig = (state: AppState) => {
  const janusConfig = selectApiConfig(state).janus || emptyObject;
  const envCookie = state?.environmentConfig?.env || {};

  // Fallback if state values are not available
  if (!Object.keys(janusConfig).length) {
    return { ...janusConfig, url: marketplace.api.janus.url };
  }

  // dont use akamai in dev or non ZFC paths, prefer direct in server side ops.
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.hostname?.includes('marty')
      ? `https://${window.location.hostname}/directapi/janus`
      : janusConfig.url;
  return { ...janusConfig, url: envCookie.janus ? janusConfig.url : akamaiOrDirectUrl };
};

export const selectSponsoredAdsConfig = (state: AppState) => {
  const sponsoredAdsConfig = selectApiConfig(state).sponsoredAds || emptyObject;
  const envCookie = state?.environmentConfig?.env || {};

  // Fallback if state values are not available
  if (!Object.keys(sponsoredAdsConfig).length) {
    return { ...sponsoredAdsConfig, url: marketplace.api.sponsoredAds.url };
  }

  // dont use akamai in dev or non ZFC paths, prefer direct in server side ops.
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.hostname?.includes('marty')
      ? `https://${window.location.hostname}/directapi/ads`
      : sponsoredAdsConfig.url;
  return { ...sponsoredAdsConfig, url: envCookie.sponsoredAds ? sponsoredAdsConfig.url : akamaiOrDirectUrl };
};

export const selectAccountConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const accountConfig = selectApiConfig(state).account || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(accountConfig).length) {
    return { ...accountConfig, url: isFeatureMafiaAkamai ? marketplace.api.mafia.akamaiUrl : marketplace.api.mafia.url };
  }

  return { ...accountConfig, url: isFeatureMafiaAkamai ? accountConfig.akamaiUrl : accountConfig.url };
};

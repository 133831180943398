import { useVariant } from '@unleash/proxy-client-react';
import { useSelector } from 'react-redux';

import type { AppState } from 'types/app';
import { selectUnleashToggle } from 'selectors/features';

export const useIsEnrolledInVariant = (featureFlag: string, variantName: 'treatment' | 'control' | string = 'treatment') => {
  useVariant(featureFlag);

  const isEnrolled = useSelector((state: AppState) => selectUnleashToggle(featureFlag, state))?.variant?.name === variantName;

  return { isEnrolled };
};

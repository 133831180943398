import { createSelector } from 'reselect';

import type { AppState } from 'types/app';
import { selectRecommendedFit } from 'selectors/sizing';

export const selectProductData = (state: AppState) => state.product;

export const selectReviewData = (state: AppState) => selectProductData(state)?.reviewData;

export const selectIsLoadingReviews = (state: AppState) => selectReviewData(state)?.isLoading;

export const selectProductReviews = (state: AppState) => selectReviewData(state)?.reviews;

export const selectReviewCount = (state: AppState) => selectProductReviews(state)?.length;

export const selectSymphonyStory = (state: AppState) => selectProductData(state)?.symphonyStory;

export const selectSymphonyStorySlotData = (state: AppState) => selectSymphonyStory(state)?.slotData;

export const selectSizeSymphonyContent = (state: AppState) => selectSymphonyStorySlotData(state)?.['buybox-size-1'];

export const selectProductDetail = (state: AppState) => selectProductData(state)?.detail;

export const selectProductSizing = (state: AppState) => selectProductDetail(state)?.sizing;

export const selectProductAirplaneCache = (state: AppState) => selectProductSizing(state)?.airplaneCache;

export const selectGenericSizeBiases = (state: AppState) => selectProductData(state).genericSizeBiases;

export const selectSelectedSizing = (state: AppState) => selectProductData(state)?.selectedSizing;

export const selectSizingPredictionValue = (state: AppState) => selectProductData(state)?.sizingPredictionValue;

export const selectHasSizingPredictionLoaded = (state: AppState) => selectProductData(state)?.hasSizingPredictionLoaded;

export const selectPredictedSize = createSelector(
  selectRecommendedFit,
  selectSizingPredictionValue,
  (recommendedFit, sizingPredictionValue) => recommendedFit || sizingPredictionValue || '',
  {
    // This selector returns 1 of its 2 arguments argument. Explicitly allow this behavior and silence warnings
    devModeChecks: { identityFunctionCheck: 'never' }
  }
);

export const selectShowGenericSizeBiasReco = createSelector(
  selectProductData,
  selectSizeSymphonyContent,
  ({ genericSizeBiases }, sizeSymphonyContent) => !sizeSymphonyContent && Boolean(genericSizeBiases)
);

export const selectSEOBreadcrumbs = (state: AppState) => selectProductDetail(state)?.seo?.breadcrumbs;

export const selectZsoBrandLink = (state: AppState) => selectSEOBreadcrumbs(state)?.brand;

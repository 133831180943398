import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { AirplaneCacheUnitOption } from 'types/AirplaneCache';
import { Toggle } from 'tailwind/components/Toggle/Toggle';

import css from 'styles/components/productdetail/sizeUnitTabs.scss';

interface Props {
  onChange: (option: AirplaneCacheUnitOption) => void;
  options: AirplaneCacheUnitOption[];
  selectedOption: AirplaneCacheUnitOption;
  isOneOfPdpEnhancementsTreatmentEnabled?: boolean;
}

export default function SizeUnitTabs(props: Props) {
  const { onChange, options, selectedOption, isOneOfPdpEnhancementsTreatmentEnabled } = props;
  const { testId } = useMartyContext();
  const optionLabels = options.map(option => option?.label);

  return (
    <div className={css.container} data-test-id={testId('sizingUnitPicker')}>
      {isOneOfPdpEnhancementsTreatmentEnabled && (
        <Toggle
          testId={testId('sizingUnitOption')}
          options={optionLabels}
          onValueChange={label => onChange(options.find(option => option.label === label)!)}
        />
      )}
      {!isOneOfPdpEnhancementsTreatmentEnabled &&
        options.map(option => (
          <button
            data-test-id={testId('sizingUnitOption')}
            className={cn(css.tab, {
              [css.selected]: option.id === selectedOption.id
            })}
            key={option.id}
            onClick={() => onChange(option)}
            type="button"
          >
            {option.label}
          </button>
        ))}
    </div>
  );
}

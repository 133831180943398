import type { SizeOptionConfig, SizeOptions } from './Toggle.types';

export const sizeVariants: Record<SizeOptions, SizeOptionConfig> = {
  base: {
    button: 'px-3 py-1 text-sm',
    container: 'p-1',
    indicator: 'bottom-1 top-1 flex items-center justify-center'
  },
  large: {
    button: 'px-4 py-1.5 text-base',
    container: 'p-1.5',
    indicator: 'bottom-1.5 top-1.5 flex items-center justify-center'
  },
  small: {
    button: 'px-2 py-0.5 text-xs',
    container: 'p-0.5',
    indicator: 'bottom-0.5 top-0.5 flex items-center justify-center'
  }
};

import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect, useSelector } from 'react-redux';
import type { Location } from 'history';

import { cn } from 'helpers/classnames';
import AirplaneFieldset from 'components/productdetail/stylepicker/AirplaneFieldset';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import { capitalize } from 'helpers/index';
import GenericSizeBiasReco from 'components/productdetail/stylepicker/GenericSizeBiasReco';
import RecommendedSizing from 'components/productdetail/stylepicker/RecommendedSizing';
import type {
  AirplaneButtonDimension,
  AirplaneCache,
  AirplaneCacheConcreteDimensionOption,
  AirplaneCacheDimensionOption,
  AirplaneCacheGenderOption,
  AirplaneCacheStock,
  SetItemSelection,
  SupportedDimension
} from 'types/AirplaneCache';
import type { FormattedProductSizing, ProductDetailState } from 'reducers/detail/productDetail';
import type { AppState } from 'types/app';
import type { SizingValue } from 'types/cloudCatalog';
import type { SelectedSizing } from 'types/product';
import { trackEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import { evCountryUnit, evEmptySizeOptions, evGender } from 'events/product';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectPredictedSize, selectProductAirplaneCache, selectShowGenericSizeBiasReco } from 'selectors/product';
import { useFeaturePdpEnhancements } from 'hooks/unleash/useFeaturePdpEnhancements';

/** FormattedProductSizing except the `airplaneCache` field is required */
export type AirplaneProductSizing = Omit<FormattedProductSizing, 'airplaneCache'> & Required<Pick<FormattedProductSizing, 'airplaneCache'>>;

interface OwnProps {
  sizing: AirplaneProductSizing;
  product: ProductDetailState;
  onDemandSizingGender: string | null;
  showRecommendedSizing: boolean | '' | undefined;
  onSizeChange: (
    options: SizingValue[],
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | React.MouseEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  handleSetRecommendedFit: (predictedSize: string) => void;
  selectedSize: string | undefined;
  location: Location;
  handleCalculateSizeClick: () => void;
  isOnDemandSizingModalOpen: boolean;
  isDesktopView: boolean;
  isSelectSizeTooltipVisible: boolean;
  styleId: string;
  makeProductNotifyMe?: () => JSX.Element;
  showProductNotifyMe: boolean;
  removeGenderFilter?: boolean;
}

type AirplaneSeatSizingActionProps = {
  productAgeGroupChanged: Function;
  productGenderChanged: Function;
  productSingleShoeSideChanged: Function;
  productSizeRangeChanged: Function;
  productSizeUnitChanged: Function;
};

type AirplaneSeatSizingProps = OwnProps & PropsFromRedux & AirplaneSeatSizingActionProps;

const GENDERED_SIZE_LEGEND_HEADING_PREFIX: Record<string, string> = {
  boys: "Boy's",
  girls: "Girl's",
  men: "Men's",
  women: "Women's"
};

const KIDS_GENDERED_SIZE_LEGEND_HEADING_PREFIX: Record<string, string> = {
  mens: 'Boys',
  womens: 'Girls'
};

const DEFAULT_SIZE_LEGEND_HEADING = 'Size:';

const AGE_GROUP_SIZE_LEGEND_HEADING: Record<AirplaneCacheStock['ageGroup'], string> = {
  'adult': DEFAULT_SIZE_LEGEND_HEADING,
  'big-kids': 'Big Kid',
  'infant': 'Infant',
  'kids': 'Kids',
  'little-kids': 'Little Kid',
  'toddler': 'Toddler'
};

function shouldShowSingleShoeSideOptions({ all: { shoeTypeOptions } }: { all: Pick<AirplaneCache['all'], 'shoeTypeOptions'> }) {
  return shoeTypeOptions.length > 1;
}

function shouldShowSelectDimensionWarning(
  section: AirplaneButtonDimension,
  isSelectSizeTooltipVisible: boolean,
  airplaneCache: Pick<AirplaneCache, 'sizeDimensionKey' | 'widthDimensionKey'>,
  selectedSizing: SelectedSizing
): boolean {
  const hasSelectDimensionWarning = section === 'size' || section === 'width';
  if (!hasSelectDimensionWarning) {
    return false;
  }
  const { sizeDimensionKey, widthDimensionKey } = airplaneCache;
  const dimensionKey = section === 'size' ? sizeDimensionKey : widthDimensionKey;
  const dimensionValue = selectedSizing[dimensionKey!];
  return isSelectSizeTooltipVisible && !dimensionValue;
}

export function getSizeSelectorLabel({
  selectedAgeGroup,
  selectedGender,
  selectedSingleShoeSide
}: {
  selectedAgeGroup: AirplaneCacheStock['ageGroup'];
  selectedGender: AirplaneCacheStock['gender'];
  selectedSingleShoeSide: AirplaneCacheStock['shoeType'];
}): LegendHeading {
  const ageGroupLabel = AGE_GROUP_SIZE_LEGEND_HEADING[selectedAgeGroup];
  const genderLabel = KIDS_GENDERED_SIZE_LEGEND_HEADING_PREFIX[selectedGender];
  const singleShoeSideLabel = selectedSingleShoeSide ? capitalize(selectedSingleShoeSide.toLowerCase()) : '';
  return { prefix: `${ageGroupLabel} ${genderLabel} ${singleShoeSideLabel} Size:` };
}

export interface LegendHeading {
  prefix?: string;
  suffix?: string;
}

export function makeSizeLegendHeadingFromState(airplaneCache: AirplaneCache, isOneOfPdpEnhancementsTreatmentEnabled?: boolean): LegendHeading {
  const {
    all: { genderOptions },
    constraints: { ageGroup: selectedAgeGroup, gender: selectedGender, shoeType: selectedSingleShoeSide },
    sizeHint
  } = airplaneCache;

  const showSingleShoeSideOptions = shouldShowSingleShoeSideOptions(airplaneCache);
  const side =
    showSingleShoeSideOptions && selectedSingleShoeSide
      ? capitalize(selectedSingleShoeSide.toLowerCase())
      : capitalize(airplaneCache.constraints.shoeType?.toLowerCase() ?? '');
  const genderLabel = genderOptions
    .find(option => option.ageGroup === selectedAgeGroup && option.constraintValue === selectedGender)
    ?.label?.toLowerCase();

  const formattedSizeHint = sizeHint ? `${sizeHint} ` : '';
  const typicalLegendSuffix = `${formattedSizeHint}Sizes:`;

  const unitOptions = airplaneCache.all.countryOrUnitOptions;
  const selectedUnitOption = unitOptions.find(option => option.constraintValue === airplaneCache.constraints.countryOrUnit);
  const { sizeOptions } = airplaneCache.all;
  const selectedSize =
    sizeOptions.length === 1
      ? sizeOptions[0]
      : airplaneCache.all.sizeOptions.find(
          sizeOption =>
            sizeOption.constraintValue === airplaneCache.constraints.sizeDimensionValue &&
            sizeOption.countryOrUnit === airplaneCache.constraints.countryOrUnit
        );

  if (selectedAgeGroup && !selectedSingleShoeSide && selectedAgeGroup !== 'adult') {
    if (isOneOfPdpEnhancementsTreatmentEnabled) {
      return getSizeLabel({ suffixLabel: selectedAgeGroup, sizeLabel: selectedSize?.label, country: selectedUnitOption?.id });
    }
    return { prefix: `${AGE_GROUP_SIZE_LEGEND_HEADING[selectedAgeGroup]} Size:` };
  }
  if (selectedAgeGroup && selectedGender && selectedSingleShoeSide && selectedAgeGroup !== 'adult') {
    if (isOneOfPdpEnhancementsTreatmentEnabled) {
      return getSizeLabel({ suffixLabel: selectedAgeGroup, sizeLabel: selectedSize?.label, country: selectedUnitOption?.id });
    }
    return getSizeSelectorLabel({ selectedAgeGroup, selectedGender, selectedSingleShoeSide });
  }

  if (!genderLabel) {
    if (side) {
      if (isOneOfPdpEnhancementsTreatmentEnabled) {
        return getSizeLabel({
          suffixLabel: `${side} Single Shoe`,
          sizeLabel: selectedSize?.label,
          country: selectedUnitOption?.id
        });
      }
      return { prefix: `${side} Single Shoe ${typicalLegendSuffix}` };
    }
    // this should not be reachable
    return { prefix: `${formattedSizeHint}${DEFAULT_SIZE_LEGEND_HEADING}` };
  }
  const genderPrefix = GENDERED_SIZE_LEGEND_HEADING_PREFIX[genderLabel];
  if (side) {
    if (isOneOfPdpEnhancementsTreatmentEnabled) {
      return getSizeLabel({
        suffixLabel: `${genderPrefix} ${side} Single Shoe`,
        sizeLabel: selectedSize?.label,
        country: selectedUnitOption?.id
      });
    }
    return { prefix: `${genderPrefix} ${side} Single Shoe ${typicalLegendSuffix}` };
  }

  if (isOneOfPdpEnhancementsTreatmentEnabled) {
    return getSizeLabel({
      suffixLabel: GENDERED_SIZE_LEGEND_HEADING_PREFIX[genderLabel] ?? '',
      sizeLabel: selectedSize?.label,
      country: selectedUnitOption?.id
    });
  }

  return { prefix: `${genderPrefix} ${typicalLegendSuffix}` };
}

export const getSizeLabel = ({ suffixLabel, sizeLabel, country }: { suffixLabel: string; sizeLabel?: string; country?: string }): LegendHeading => {
  const sizeLabelPrefix = `${country} ${DEFAULT_SIZE_LEGEND_HEADING} `;

  if (!sizeLabel) {
    return { prefix: sizeLabelPrefix };
  }

  return { prefix: sizeLabelPrefix, suffix: `${sizeLabel} (${suffixLabel})` };
};

function shouldDisplaySizeOrWidthOption(
  airplaneCache: Pick<AirplaneCache, 'constraints' | 'virtualDimensions'>,
  option: AirplaneCacheConcreteDimensionOption
): boolean {
  const { constraints, virtualDimensions } = airplaneCache;
  for (const vd of virtualDimensions) {
    if (constraints[vd.constraintsKey] && option[vd.constraintsKey] !== constraints[vd.constraintsKey]) {
      return false;
    }
  }
  return true;
}

export function AirplaneSeatSizing({
  handleCalculateSizeClick,
  handleSetRecommendedFit,
  isDesktopView,
  isOnDemandSizingModalOpen,
  isSelectSizeTooltipVisible,
  location,
  onDemandSizingGender,
  onCloseModal,
  onOpenModal,
  onSizeChange,
  product,
  styleId,
  productAgeGroupChanged,
  productGenderChanged,
  productSingleShoeSideChanged,
  productSizeRangeChanged,
  productSizeUnitChanged,
  showGenericSizeBiasReco,
  showRecommendedSizing,
  sizing: { allUnits, valueIdToName },
  makeProductNotifyMe,
  showProductNotifyMe,
  removeGenderFilter
}: AirplaneSeatSizingProps) {
  const airplaneCache = useSelector(selectProductAirplaneCache);
  const predictedSize = useSelector(selectPredictedSize);
  const { isOneOfPdpEnhancementsTreatmentEnabled } = useFeaturePdpEnhancements();

  const isMoveWidthAboveSizeOptions = isOneOfPdpEnhancementsTreatmentEnabled;

  const { genericSizeBiases, selectedSizing } = product;
  const { text } = genericSizeBiases || {};
  const detail = product.detail!;
  const { productId } = detail;

  useEffectOnce(() => {
    if (airplaneCache) {
      trackEmptyOptionsEvent();
    }
  });

  if (!airplaneCache) {
    return null;
  }

  const { sizeDimensionKey, sizeDimensionName, widthDimensionKey, widthDimensionName } = airplaneCache;

  const sizeOptions = allUnits[0]?.values;
  const widthOptions = allUnits[1]?.values;

  const unitOptions = airplaneCache.all.countryOrUnitOptions;
  const showUnitOptions = unitOptions.length > 1;
  const selectedUnitOption = unitOptions.find(option => option.constraintValue === airplaneCache.constraints.countryOrUnit);

  const selectedSizeVal = selectedSizing?.d3 ? valueIdToName[selectedSizing.d3]?.value : undefined;

  const sizeLegendHeading =
    sizeDimensionName === 'size'
      ? makeSizeLegendHeadingFromState(airplaneCache, isOneOfPdpEnhancementsTreatmentEnabled)
      : { prefix: `${sizeDimensionName!} Options:` };

  const widthLegendHeading = widthDimensionName === 'width' ? 'Width Options:' : `${widthDimensionName}:`;

  const pickDisplayedOptions = (options: AirplaneCacheDimensionOption[]) =>
    options.filter(option => {
      const concreteOption = option as AirplaneCacheConcreteDimensionOption;
      return shouldDisplaySizeOrWidthOption(airplaneCache, concreteOption);
    });

  const setItemSelection: Record<SupportedDimension, SetItemSelection> = {
    ageGroup: event => {
      const target = event.target as HTMLInputElement;
      const id = target.value as AirplaneCacheStock['ageGroup'];
      productAgeGroupChanged(id);
      trackEmptyOptionsEvent();
    },
    gender: event => {
      const target = event.target as HTMLInputElement;
      const id = target.value as AirplaneCacheStock['gender'];
      const dimension = 'GENDER_DIMENSION';
      productGenderChanged(id);
      trackEvent('TE_PDP_GENDER_CLICK', `dimension:${dimension}:gender:${id}:sourcePage:${PRODUCT_PAGE}`);
      track(() => [evGender, { dimension, gender: id }]);
      trackEmptyOptionsEvent();
    },
    singleShoeSide: event => {
      const target = event.target as HTMLInputElement;
      const id = target.value as AirplaneCache['constraints']['shoeType'];
      productSingleShoeSideChanged(id);
      trackEmptyOptionsEvent();
    },
    size: event => {
      if (sizeOptions) {
        onSizeChange(sizeOptions, event);
      }
    },
    sizeRange: event => {
      const target = event.target as HTMLInputElement;
      const id = target.value;
      productSizeRangeChanged(id);
      trackEmptyOptionsEvent();
    },
    unit: event => {
      const target = event.target as HTMLInputElement;
      const id = target.id as AirplaneCacheStock['countryOrUnit'];
      productSizeUnitChanged(id);
      trackEvent('TE_PDP_COUNTRY_UNIT_CLICK', `selectedSizing:${id}:sourcePage:${PRODUCT_PAGE}`);
      track(() => [evCountryUnit, { selectedSizing: id }]);
      trackEmptyOptionsEvent();
    },
    width: event => {
      if (widthOptions) {
        onSizeChange(widthOptions, event);
      }
    }
  };

  const trackEmptyOptionsEvent = () => {
    if (pickDisplayedOptions(airplaneCache.all.sizeOptions)?.length === 0) {
      const { id } = selectedUnitOption || {};
      if (id) {
        track(() => [evEmptySizeOptions, { selectedSizing: id, product }]);
      }
    }
  };

  const makeTitleString = (section: 'size' | 'width'): LegendHeading => {
    if (shouldShowSelectDimensionWarning(section, isSelectSizeTooltipVisible, airplaneCache, selectedSizing)) {
      return { prefix: `Please select a ${section}:` };
    }
    return section === 'size' ? sizeLegendHeading : { prefix: widthLegendHeading };
  };

  const makeAgeGroupFieldset = () => {
    if (airplaneCache.all.ageGroups.length < 2) {
      return null;
    }
    const typeKey = 'ageGroup';
    return (
      <AirplaneFieldset
        applyWarningStyles={false}
        fieldsetHasWarnings={false}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        legendText={{ prefix: 'Size Group:' }}
        options={airplaneCache.all.ageGroups}
        radioButtonName={typeKey}
        setItemSelection={setItemSelection[typeKey]}
        useNarrowOptionButtons={false}
        typeKey={typeKey}
        namespace={productId}
        styleId={styleId}
      />
    );
  };

  const makeGenderFieldset = () => {
    const selectedAgeGroup = airplaneCache.constraints.ageGroup;
    const noAgeGroupSelected = !selectedAgeGroup;
    const hasMultipleGenderOptions = airplaneCache.all.genderOptions.length > 1;
    const shouldShowGenderOptions =
      !removeGenderFilter &&
      ((hasMultipleGenderOptions && noAgeGroupSelected) ||
        airplaneCache.all.genderOptions.filter(option => option.ageGroup === selectedAgeGroup).length > 1);
    if (!shouldShowGenderOptions) {
      return null;
    }
    const pickDisplayedOptions = (options: AirplaneCacheDimensionOption[]) =>
      options.filter(option => {
        const { ageGroup } = option as AirplaneCacheGenderOption;
        return ageGroup === airplaneCache.constraints.ageGroup;
      });
    const typeKey = 'gender';
    return (
      <AirplaneFieldset
        applyWarningStyles={false}
        fieldsetHasWarnings={false}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        legendText={{ prefix: 'Gender:' }}
        options={airplaneCache.all.genderOptions}
        pickDisplayedOptions={pickDisplayedOptions}
        radioButtonName={typeKey}
        setItemSelection={setItemSelection[typeKey]}
        typeKey={typeKey}
        namespace={productId}
        styleId={styleId}
      />
    );
  };

  const makeSizeRangeFieldset = () => {
    const shouldShowSizeRangeOptions = airplaneCache.all.sizeRangeOptions.length > 1;
    if (!shouldShowSizeRangeOptions) {
      return null;
    }

    // don't display strikeouts for size range options. their concrete
    // dimension option sets are mutually exclusive.
    const isInStockFn = () => true;

    const typeKey = 'sizeRange';
    return (
      <AirplaneFieldset
        applyWarningStyles={false}
        fieldsetHasWarnings={false}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        isInStockFn={isInStockFn}
        legendText={{ prefix: 'Size Range:' }}
        options={airplaneCache.all.sizeRangeOptions}
        radioButtonName={typeKey}
        setItemSelection={setItemSelection[typeKey]}
        typeKey={typeKey}
        namespace={productId}
        styleId={styleId}
      />
    );
  };

  const makeSingleShoeSideFieldset = () => {
    if (!shouldShowSingleShoeSideOptions(airplaneCache)) {
      return null;
    }
    const isInStockFn = (option: AirplaneCacheDimensionOption) => {
      const availableOptions = airplaneCache.available.shoeTypeOptions;
      const constraintValue = option.constraintValue as AirplaneCacheStock['shoeType'];
      return availableOptions.includes(constraintValue);
    };
    const isSelectedFn = (option: AirplaneCacheDimensionOption) => option.constraintValue === airplaneCache.constraints.shoeType;
    const typeKey = 'singleShoeSide';
    return (
      <AirplaneFieldset
        applyWarningStyles={false}
        fieldsetHasWarnings={false}
        isInStockFn={isInStockFn}
        isSelectedFn={isSelectedFn}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        legendText={{ prefix: 'Select a Side:' }}
        options={airplaneCache.all.shoeTypeOptions}
        radioButtonName={typeKey}
        setItemSelection={setItemSelection[typeKey]}
        typeKey={typeKey}
        namespace={productId}
        styleId={styleId}
      />
    );
  };

  const makeSizeFieldset = () => {
    const typeKey = 'size';
    const options = airplaneCache.all.sizeOptions;

    const applyWarningStyles = shouldShowSelectDimensionWarning(typeKey, isSelectSizeTooltipVisible, airplaneCache, selectedSizing);

    let sizingUnitPicker = undefined;
    if (showUnitOptions) {
      sizingUnitPicker = {
        options: unitOptions,
        // selectedUnitOption is always set if there are sizing unit options,
        // and if showUnitOptions is true, there are sizing unit options.
        selectedOption: selectedUnitOption!,
        setItemSelection: setItemSelection.unit
      };
    }

    const isSelectedFn = (option: AirplaneCacheDimensionOption) => {
      const selectedSize = selectedSizing[sizeDimensionKey!];
      return !!(selectedSize && option.constraintValue === selectedSize);
    };

    // sizeDimensionKey is always defined if we have size options
    const radioButtonName = sizeDimensionKey!;

    return (
      <AirplaneFieldset
        applyWarningStyles={applyWarningStyles}
        fieldsetHasWarnings={true}
        isSelectedFn={isSelectedFn}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        legendId="sizingChooser"
        legendText={makeTitleString(typeKey)}
        options={options}
        pickDisplayedOptions={pickDisplayedOptions}
        sizingUnitPicker={sizingUnitPicker}
        radioButtonName={radioButtonName}
        setItemSelection={setItemSelection[typeKey]}
        typeKey={typeKey}
        useNarrowOptionButtons={airplaneCache.useNarrowButtonsForSizeOptions}
        namespace={productId}
        styleId={styleId}
        isOneOfPdpEnhancementsTreatmentEnabled={isOneOfPdpEnhancementsTreatmentEnabled}
      />
    );
  };

  const makeWidthFieldset = () => {
    const typeKey = 'width';
    const options = airplaneCache.all.widthOptions;
    const hasDisplayableOptions = options.some(option => shouldDisplaySizeOrWidthOption(airplaneCache, option));

    if (!hasDisplayableOptions) {
      return null;
    }

    const applyWarningStyles = shouldShowSelectDimensionWarning(typeKey, isSelectSizeTooltipVisible, airplaneCache, selectedSizing);

    const pickDisplayedOptions = (options: AirplaneCacheDimensionOption[]) =>
      options.filter(option => {
        const concreteOption = option as AirplaneCacheConcreteDimensionOption;
        return shouldDisplaySizeOrWidthOption(airplaneCache, concreteOption);
      });
    const isSelectedFn = (option: AirplaneCacheDimensionOption) => {
      const { constraintValue } = option;
      const selectedWidth = selectedSizing[widthDimensionKey!];
      return !!(selectedWidth && constraintValue === selectedWidth);
    };

    // widthDimensionKey is always defined if we have width options
    const radioButtonName = airplaneCache.widthDimensionKey!;

    return (
      <AirplaneFieldset
        applyWarningStyles={applyWarningStyles}
        fieldsetHasWarnings={true}
        isSelectedFn={isSelectedFn}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        legendId="widthChooser"
        legendText={makeTitleString(typeKey)}
        options={options}
        pickDisplayedOptions={pickDisplayedOptions}
        radioButtonName={radioButtonName}
        setItemSelection={setItemSelection[typeKey]}
        typeKey={typeKey}
        namespace={productId}
        styleId={styleId}
      />
    );
  };

  const hasSizedBias = showGenericSizeBiasReco && genericSizeBiases && text && !predictedSize;

  return (
    <div className="flex flex-col gap-y-6">
      {(hasSizedBias || showRecommendedSizing) && (
        <div className="flex flex-wrap items-center justify-between gap-y-2">
          {hasSizedBias && (
            <div className={cn('flex flex-col gap-y-2', !text && !showRecommendedSizing ? 'hidden' : null)}>
              {!predictedSize && <GenericSizeBiasReco currentProductId={productId} genericSizeBiases={genericSizeBiases} />}
            </div>
          )}
          {showRecommendedSizing && (
            <RecommendedSizing
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
              product={product}
              gender={onDemandSizingGender}
              handleSetRecommendedFit={handleSetRecommendedFit}
              selectedSize={selectedSizeVal}
              location={location}
              handleCalculateSizeClick={handleCalculateSizeClick}
              isOnDemandSizingModalOpen={isOnDemandSizingModalOpen}
              isDesktopView={isDesktopView}
              isIncludeIcon={!hasSizedBias}
            />
          )}
        </div>
      )}
      <div className="flex flex-col gap-y-4">
        {makeAgeGroupFieldset()}
        {makeGenderFieldset()}
        {isMoveWidthAboveSizeOptions && makeWidthFieldset()}
        {makeSizeRangeFieldset()}
        {makeSingleShoeSideFieldset()}
        {makeSizeFieldset()}
        {showProductNotifyMe && makeProductNotifyMe && makeProductNotifyMe()}
        {!isMoveWidthAboveSizeOptions && makeWidthFieldset()}
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  showGenericSizeBiasReco: selectShowGenericSizeBiasReco(state)
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AirplaneSeatSizing);

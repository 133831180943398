import {
  CART_CHANGE_FAILURE,
  CART_ERROR,
  CART_IS_LOADING,
  CART_RESET_STATE,
  PIN_IS_VALID,
  RECEIVE_CART_ITEMS,
  RECEIVE_CART_PROMOS,
  RECEIVE_CART_RECOS,
  RECEIVE_PIN,
  RECEIVE_TRACKING_NUMBER,
  REQUEST_CART_COUNT,
  REQUEST_CART_UPDATE,
  SET_CART_LOADED,
  SHOW_CART_MODAL,
  UPDATE_CART_COUNT
} from 'constants/reduxActions';
import type { CartResponse } from 'types/mafia';
import type { AllJanusSlotNames } from 'types/janus';
import type { JanusRecos } from 'reducers/recos';
import { extractDataFromWidget } from 'reducers/recos';
import type { EmptyObject } from 'types/utility';
import type { CartActions } from 'actions/cart';

export interface CartState {
  isLoaded: boolean;
  cartObj: CartResponse | EmptyObject;
  cartCount: number | null;
  error: string | null;
  loading?: boolean;
  isModalShowing?: boolean;
  addedStockId?: string;
  pin?: string;
  trackingNumber?: string;
  isValid?: boolean;
  promos?: any; // TODO ts use correct type when landing page is typed
  janus?: JanusRecos;
  lastReceivedRecoKey?: string;
  isCartCountLoaded: boolean;
  isCartCountLoading: boolean;
}

const initialState: CartState = {
  isLoaded: false,
  cartObj: {},
  cartCount: null,
  error: null,
  isCartCountLoaded: false,
  isCartCountLoading: false
};

export default function cart(state: Readonly<CartState> = initialState, action: CartActions): CartState {
  switch (action.type) {
    case RECEIVE_CART_ITEMS:
      const { cartObj, cartCount } = action;
      return {
        ...state,
        cartObj,
        cartCount,
        isLoaded: true,
        loading: false,
        isCartCountLoaded: true
      };
    case RECEIVE_CART_PROMOS:
      const { promos } = action;
      return { ...state, promos };
    case RECEIVE_CART_RECOS:
      const { key, data } = action;
      const keys = Object.keys(data) as AllJanusSlotNames[];
      const newCartRecos: JanusRecos = {};
      const reco = data[keys[0]!];
      if (reco) {
        newCartRecos[key] = extractDataFromWidget(reco);
      }

      return { ...state, janus: { ...newCartRecos }, lastReceivedRecoKey: key };
    case REQUEST_CART_COUNT:
      return { ...state, isCartCountLoading: true };
    case UPDATE_CART_COUNT:
      const { cartCount: updatedCartCount } = action;
      return { ...state, cartCount: updatedCartCount, isCartCountLoaded: true, isCartCountLoading: false };
    case REQUEST_CART_UPDATE:
      return { ...state, loading: true, error: null };
    case CART_CHANGE_FAILURE:
      return { ...state, loading: false };
    case CART_ERROR:
      const { error } = action;
      return { ...state, error };
    case CART_IS_LOADING:
      return { ...state, loading: true };
    case SHOW_CART_MODAL: {
      const { isShowing, stockId } = action;
      return {
        ...state,
        isModalShowing: isShowing,
        addedStockId: isShowing ? stockId : undefined
      };
    }
    case RECEIVE_PIN: {
      const { pin } = action;
      return { ...state, pin };
    }
    case PIN_IS_VALID: {
      const { isValid } = action;
      return { ...state, isValid };
    }
    case RECEIVE_TRACKING_NUMBER: {
      const { trackingNumber } = action;
      return { ...state, trackingNumber };
    }
    case CART_RESET_STATE: {
      return initialState;
    }
    case SET_CART_LOADED: {
      return { ...state, isCartCountLoaded: true };
    }
    default:
      return state;
  }
}

export const CHECKOUT_FETCH_ORDERS_BY_PURCHASE_ID = 'CHECKOUT_FETCH_ORDERS_BY_PURCHASE_ID';
export const CHECKOUT_FETCH_AKITA_ESTIMATE = 'CHECKOUT_FETCH_AKITA_ESTIMATE';
export const CHECKOUT_STORE_AKITA_ESTIMATE = 'CHECKOUT_STORE_AKITA_ESTIMATE';
export const CHECKOUT_ON_ADD_NEW_BILLING_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK = 'CHECKOUT_ON_ADD_NEW_BILLING_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK';
export const CHECKOUT_ON_ADD_NEW_SHIP_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK = 'CHECKOUT_ON_ADD_NEW_SHIP_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK';
export const CHECKOUT_ON_CHANGE_SHIPPING_CLICK = 'CHECKOUT_ON_CHANGE_SHIPPING_CLICK ';
export const CHECKOUT_ON_ASIN_HAS_GONE_OOS = 'CHECKOUT_ON_ASIN_HAS_GONE_OOS';
export const CHECKOUT_ON_HIDE_BILLING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK =
  'CHECKOUT_ON_HIDE_BILLING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK';
export const CHECKOUT_ON_HIDE_BILLING_ADDRESS_MODAL_CLICK = 'CHECKOUT_ON_HIDE_BILLING_ADDRESS_MODAL_CLICK';
export const CHECKOUT_ON_HIDE_NEW_SHIPPING_ADDRESS_MODAL_CLICK = 'CHECKOUT_ON_HIDE_NEW_SHIPPING_ADDRESS_MODAL_CLICK';
export const CHECKOUT_ON_HIDE_SHIPPING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK =
  'CHECKOUT_ON_HIDE_SHIPPING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK';
export const CHECKOUT_ON_USE_SHIPPING_ADDRESS_CLICK = 'CHECKOUT_ON_USE_SHIPPING_ADDRESS_CLICK';
export const CHECKOUT_ON_USE_BILLING_ADDRESS_CLICK = 'CHECKOUT_ON_USE_BILLING_ADDRESS_CLICK';
export const CHECKOUT_ON_MAX_STEP_IS_CART_STEP = 'CHECKOUT_ON_MAX_STEP_IS_CART_STEP';
export const CHECKOUT_ON_MOVE_TO_FAVORITES_CLICK = 'CHECKOUT_ON_MOVE_TO_FAVORITES_CLICK';
export const CHECKOUT_ON_SHOW_ADD_NEW_SHIPPING_ADDRESS_MODAL_CLICK = 'CHECKOUT_ON_SHOW_ADD_NEW_SHIPPING_ADDRESS_MODAL_CLICK';
export const CHECKOUT_ON_VIEW_SALES_TAX_CLICK = 'CHECKOUT_ON_VIEW_SALES_TAX_CLICK';
export const CHECKOUT_PAGEVIEW = 'CHECKOUT_PAGEVIEW';
export const CHECKOUT_RECEIVE_ORDER_INFORMATION = 'CHECKOUT_RECEIVE_ORDER_INFORMATION';
export const CHECKOUT_REDEEM_REWARDS_POINTS = 'CHECKOUT_REDEEM_REWARDS_POINTS';
export const CHECKOUT_USE_SUGGESTED_ADDRESS = 'CHECKOUT_USE_SUGGESTED_ADDRESS';
export const CHECKOUT_SEND_TO_DESIRED_PAGE = 'CHECKOUT_SEND_TO_DESIRED_PAGE';
export const CHECKOUT_SEND_TO_MAX_AVAILABLE_STEP = 'CHECKOUT_SEND_TO_MAX_AVAILABLE_STEP';
export const CHECKOUT_TRACK_NOT_MAX_STEP = 'CHECKOUT_TRACK_NOT_MAX_STEP';
export const CHECKOUT_SEND_TO_NEW_ADDRESS_WHEN_NO_SAVED_ADDRESSES = 'CHECKOUT_SEND_TO_NEW_ADDRESS_WHEN_NO_SAVED_ADDRESSES';
export const CHECKOUT_SET_CONFIRMATION_PIXEL_PAYLOAD = 'CHECKOUT_SET_CONFIRMATION_PIXEL_PAYLOAD';
export const CHECKOUT_CLOSE_SELECT_ADDRESS = 'CHECKOUT_CLOSE_SELECT_ADDRESS';
export const CHECKOUT_EDIT_ADDRESS = 'CHECKOUT_EDIT_ADDRESS';
export const CHECKOUT_VERIFY_ADDRESS_PAGEVIEW = 'CHECKOUT_VERIFY_ADDRESS_PAGEVIEW';
export const CHECKOUT_USE_PAYMENT_METHOD = 'CHECKOUT_USE_PAYMENT_METHOD';
export const CHECKOUT_ADD_NEW_PAYMENT_CLICK = 'CHECKOUT_ADD_NEW_PAYMENT_CLICK';
export const CHECKOUT_CLOSE_PAYMENT_MODAL = 'CHECKOUT_CLOSE_PAYMENT_MODAL';
export const CHECKOUT_CLOSE_PAYMENT = 'CHECKOUT_CLOSE_PAYMENT';
export const CHECKOUT_ADD_PAYMENT_INSTRUMENT = 'CHECKOUT_ADD_PAYMENT_INSTRUMENT';
export const CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT = 'CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT';
export const CHECKOUT_CLOSE_ADDRESSLIST_MODAL = 'CHECKOUT_CLOSE_ADDRESSLIST_MODAL';
export const CHECKOUT_CLOSE_SUGGESTED_ADDRESS_MODAL = 'CHECKOUT_CLOSE_SUGGESTED_ADDRESS_MODAL';
export const CHECKOUT_CHANGE_QUANTITY = 'CHECKOUT_CHANGE_QUANTITY';
export const CHECKOUT_USE_NEW_ADDRESS = 'CHECKOUT_USE_NEW_ADDRESS';
export const CHECKOUT_UPDATE_CC_EXPIRATION = 'CHECKOUT_UPDATE_CC_EXPIRATION';
export const CHECKOUT_TOGGLE_IS_PRIMARY = 'CHECKOUT_TOGGLE_IS_PRIMARY';
export const CHECKOUT_APPLY_PROMO = 'CHECKOUT_APPLY_PROMO';
export const CHECKOUT_TOGGLE_ITEMS = 'CHECKOUT_TOGGLE_ITEMS';
export const CHECKOUT_ON_CHANGE_BILLING_ADDRESS_CLICK = 'CHECKOUT_ON_CHANGE_BILLING_ADDRESS_CLICK';
export const CHECKOUT_ON_SELECT_SHIPPING_SPEED = 'CHECKOUT_ON_SELECT_SHIPPING_SPEED';
export const CHECKOUT_SET_IS_ENROLLED_IN_REWARDS = 'CHECKOUT_SET_IS_ENROLLED_IN_REWARDS';
export const CONFIGURE_CHECKOUT = 'CONFIGURE_CHECKOUT';
export const CONFIGURE_CHECKOUT_SUCCESS = 'CONFIGURE_CHECKOUT_SUCCESS';
export const DELETE_CHECKOUT_ADDRESS = 'DELETE_CHECKOUT_ADDRESS';
export const PLACE_ORDER = 'PLACE_ORDER';
export const REQUEST_CHECKOUT_ADDRESSES = 'REQUEST_CHECKOUT_ADDRESSES';
export const REQUEST_CHECKOUT_PAYMENTS = 'REQUEST_CHECKOUT_PAYMENTS';
export const REQUEST_CHECKOUT_REDEEMABLE_REWARDS = 'REQUEST_CHECKOUT_REDEEMABLE_REWARDS';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const SAVE_CHECKOUT_ADDRESS = 'SAVE_CHECKOUT_ADDRESS';
export const SAVE_BILLING_BEFORE_PAYMENT = 'SAVE_BILLING_BEFORE_PAYMENT';
export const SET_CHECKOUT_REDEEMABLE_REWARDS = 'SET_CHECKOUT_REDEEMABLE_REWARDS';
export const SET_SELECTED_ADDRESS_ID = 'SET_SELECTED_ADDRESS_ID';
export const SET_SELECTED_SHIPPING_ADDRESS_ID = 'SET_SELECTED_SHIPPING_ADDRESS_ID';
export const SET_SELECTED_BILLING_ADDRESS_ID = 'SET_SELECTED_BILLING_ADDRESS_ID';
export const SET_SELECTED_SUGGESTED_ADDRESS_ID = 'SET_SELECTED_SUGGESTED_ADDRESS_ID';
export const SET_SELECTED_PAYMENT_INSTRUMENT_ID = 'SET_SELECTED_PAYMENT_INSTRUMENT_ID';
export const SAVE_CHECKOUT_PAYMENT_METHOD = 'SAVE_CHECKOUT_PAYMENT_METHOD';
export const SET_CHECKOUT_DATA_LOADING = 'SET_CHECKOUT_DATA_LOADING';
export const SET_CHECKOUT_USE_AS_DEFAULTS = 'SET_CHECKOUT_USE_AS_DEFAULTS';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const SET_IS_PLACING_ORDER = 'SET_IS_PLACING_ORDER';
export const SET_USE_PROMO_BALANCE = 'SET_USE_PROMO_BALANCE';
export const SET_USE_DISCOUNT_BALANCE = 'SET_USE_DISCOUNT_BALANCE';
export const VERIFY_CHECKOUT_CREDIT_CARD = 'VERIFY_CHECKOUT_CREDIT_CARD';
export const CHECKOUT_VIEW_SHIPPING_DOWNGRADE = 'CHECKOUT_VIEW_SHIPPING_DOWNGRADE';
export const CHECKOUT_JUST_LOADED = 'CHECKOUT_JUST_LOADED';
export const TRACK_CHECKOUT_DELIVERY_PROMISE = 'TRACK_CHECKOUT_DELIVERY_PROMISE';
export const CHECKOUT_REMOVE_ITEM = 'CHECKOUT_REMOVE_ITEM';
export const CHECKOUT_TRACK_CV = 'CHECKOUT_TRACK_CV';
export const CHECKOUT_TOGGLE_PROMO_BOX = 'CHECKOUT_TOGGLE_PROMO_BOX';
export const CHECKOUT_ON_CHANGE_PAYMENT_CLICK = 'CHECKOUT_ON_CHANGE_PAYMENT_CLICK';
export const CHECKOUT_ON_PROMISE_DATE_HAS_CHANGED = 'CHECKOUT_ON_PROMISE_DATE_HAS_CHANGED';
export const CHECKOUT_FETCH_LAT_LONG = 'CHECKOUT_FETCH_LAT_LONG';
export const CHECKOUT_LOAD_AUTOCOMPLETE_SUGGESTIONS = 'CHECKOUT_LOAD_AUTOCOMPLETE_SUGGESTIONS';
export const CHECKOUT_SHIP_OPTIONS_LOADED = 'CHECKOUT_SHIP_OPTIONS_LOADED';
export const CHECKOUT_FETCH_SYMPHONY_CONTENT = 'CHECKOUT_FETCH_SYMPHONY_CONTENT';
export const CHECKOUT_RECIEVE_SYMPHONY_CONTENT = 'CHECKOUT_RECIEVE_SYMPHONY_CONTENT';
export const CHECKOUT_HAD_SHIP_OPTION_AUTO_SELECTED = 'CHECKOUT_HAD_SHIP_OPTION_AUTO_SELECTED';
export const CHECKOUT_SET_PAYMENT_METHOD_TYPE = 'CHECKOUT_SET_PAYMENT_METHOD_TYPE';
export const CHECKOUT_DELETE_PAYMENT_INSTRUMENT = 'CHECKOUT_DELETE_PAYMENT_INSTRUMENT';
export const CHECKOUT_SET_IS_AMAZON_PAY_REDIRECTING = 'CHECKOUT_SET_IS_AMAZON_PAY_REDIRECTING';
export const CHECKOUT_SET_IS_AMAZON_PAY_LOADED = 'CHECKOUT_SET_IS_AMAZON_PAY_LOADED';
export const CHECKOUT_SET_IS_AMAZON_PAY_UNABLE_TO_LOAD = 'CHECKOUT_SET_IS_AMAZON_PAY_UNABLE_TO_LOAD';
export const CHECKOUT_SET_IS_AFTER_PAY_UNABLE_TO_LOAD = 'CHECKOUT_SET_IS_AFTER_PAY_UNABLE_TO_LOAD';
export const CHECKOUT_SET_IS_AFTER_PAY_LOADED = 'CHECKOUT_SET_IS_AFTER_PAY_LOADED';
export const CHECKOUT_INIT_AMAZON_PAY = 'CHECKOUT_INIT_AMAZON_PAY';
export const CHECKOUT_ABANDON_AMAZON_PAY = 'CHECKOUT_ABANDON_AMAZON_PAY';
export const CHECKOUT_CHANGE_AMAZON_PAY_PAYMENT_CLICK = 'CHECKOUT_CHANGE_AMAZON_PAY_PAYMENT_CLICK';
export const CHECKOUT_CHANGE_AMAZON_PAY_ADDRESS_CLICK = 'CHECKOUT_CHANGE_AMAZON_PAY_ADDRESS_CLICK';
export const CHECKOUT_SET_IS_AFTER_PAY_BUTTON_UNABLE_TO_LOAD = 'CHECKOUT_SET_IS_AFTER_PAY_BUTTON_UNABLE_TO_LOAD';
export const CHECKOUT_SET_IS_AFTER_PAY_BUTTON_LOADED = 'CHECKOUT_SET_IS_AFTER_PAY_BUTTON_LOADED';
export const SAVE_CHECKOUT_AFTERPAY_PAYMENT_METHOD = 'SAVE_CHECKOUT_AFTERPAY_PAYMENT_METHOD';
export const CHECKOUT_RECEIVE_ORDER = 'CHECKOUT_RECEIVE_ORDER';
export const CHECKOUT_AFTERPAY_BUTTON_CLICK = 'CHECKOUT_AFTERPAY_BUTTON_CLICK';
export const CHECKOUT_AFTERPAY_POPUP_CLOSED = 'CHECKOUT_AFTERPAY_POPUP_CLOSED';
export const CHECKOUT_AFTERPAY_ERROR = 'CHECKOUT_AFTERPAY_ERROR';
export const CHECKOUT_AFTERPAY_IMPRESSION = 'CHECKOUT_AFTERPAY_IMPRESSION';
export const CHECKOUT_AFTERPAY_SUCCESS = 'CHECKOUT_AFTERPAY_SUCCESS';
export const CHECKOUT_SET_IS_PAYPAL_LOADED = 'CHECKOUT_SET_IS_PAYPAL_LOADED';
export const CHECKOUT_SET_IS_PAYPAL_UNABLE_TO_LOAD = 'CHECKOUT_SET_IS_PAYPAL_UNABLE_TO_LOAD';
export const CHECKOUT_STORE_PAYPAL_AUTHENTICATION_SOURCE = 'CHECKOUT_STORE_PAYPAL_AUTHENTICATION_SOURCE';
export const CHECKOUT_BEGIN_CONFIRMING_PAYPAL = 'CHECKOUT_BEGIN_CONFIRMING_PAYPAL';
export const CHECKOUT_PAYPAL_AUTHENTICATION_NOT_APPROVED = 'CHECKOUT_PAYPAL_AUTHENTICATION_NOT_APPROVED';
export const CHECKOUT_PAYPAL_BUTTON_CLICK = 'CHECKOUT_PAYPAL_BUTTON_CLICK';
export const CHECKOUT_PAYPAL_GENERATE_REDIRECT = 'CHECKOUT_PAYPAL_GENERATE_REDIRECT';
export const CHECKOUT_PAYPAL_ERROR = 'CHECKOUT_PAYPAL_ERROR';
export const CHECKOUT_PAYPAL_CONFIRM_ON_PENDING_STATUS = 'CHECKOUT_PAYPAL_CONFIRM_ON_PENDING_STATUS';
export const CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK = 'CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK';
export const CHECKOUT_UPDATE_VIP_PREFERENCE = 'CHECKOUT_UPDATE_VIP_PREFERENCE';
export const CHECKOUT_VIP_TERMS_CLICK = 'CHECKOUT_VIP_TERMS_CLICK';

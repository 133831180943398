import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { Location } from 'history';

import type { OnDemandSizingCopy } from 'types/product';
import useMartyContext from 'hooks/useMartyContext';
import { selectHasSizingPredictionLoaded, selectPredictedSize } from 'selectors/product';
import RecommendedSizeTooltip from 'components/productdetail/stylepicker/RecommendedSizeTooltip';
import { RecommendedSizeFinderModal } from 'components/productdetail/asyncProductPageModals';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { AppState } from 'types/app';
import type { ProductDetailState } from 'reducers/detail/productDetail';
import RecommendedSizingLayout from 'components/productdetail/stylepicker/RecommendedSizingLayout/RecommendedSizingLayout';
import { cn } from 'helpers/classnames';

import css from 'styles/components/productdetail/recommendedSizing.scss';

interface LinkRecommendedSizingProps {
  hasSizingPredictionLoaded?: boolean;
  predictedSize: string;
  selectedSize?: string;
  onOpenModal: () => void;
  isDesktopView: boolean;
  onDemandSizingCopy?: OnDemandSizingCopy;
  isIncludeIcon?: boolean;
}

export const LinkStyleRecommendedSizing = ({
  predictedSize,
  selectedSize,
  onOpenModal,
  isDesktopView,
  onDemandSizingCopy,
  isIncludeIcon,
  hasSizingPredictionLoaded
}: LinkRecommendedSizingProps) => {
  const { testId } = useMartyContext();

  const bothSizesSelected = predictedSize && selectedSize;
  const tooltipId = 'recommendedSizeTooltip';

  if (!onDemandSizingCopy) {
    return null;
  }

  if (!hasSizingPredictionLoaded) {
    // reserves white space while sizing prediction is loading
    return <div className="h-6" />;
  }

  const { ignoredcopy, selectedcopy, availablecopy, ignoredComponent = undefined, selectedComponent = undefined } = onDemandSizingCopy;

  const classNames = css.linkStyleCopy;

  if (bothSizesSelected && selectedSize !== predictedSize && ignoredcopy) {
    if (ignoredComponent) {
      return (
        <RecommendedSizingLayout>
          <div>{ignoredComponent}</div>
        </RecommendedSizingLayout>
      );
    }

    return (
      <RecommendedSizingLayout>
        <p className={cn(classNames, 'leading-6')}>{ignoredcopy.replace(/%s/g, predictedSize)}</p>
      </RecommendedSizingLayout>
    );
  } else if (bothSizesSelected && selectedSize === predictedSize && selectedcopy) {
    if (selectedComponent) {
      return (
        <RecommendedSizingLayout>
          <div>{selectedComponent}</div>
        </RecommendedSizingLayout>
      );
    }

    return (
      <RecommendedSizingLayout>
        <p className={cn(classNames, 'leading-6')}>{selectedcopy}</p>
      </RecommendedSizingLayout>
    );
  } else if (availablecopy) {
    return (
      <>
        {isDesktopView === true && <RecommendedSizeTooltip id={tooltipId} openModal={onOpenModal} />}
        <button
          className={cn(classNames, 'leading-6', { ['ml-[34px]']: !isIncludeIcon })}
          type="button"
          aria-describedby={tooltipId}
          data-test-id={testId('initiateSizePrediction')}
          onClick={onOpenModal}
        >
          <RecommendedSizingLayout isIncludeIcon={isIncludeIcon}>{availablecopy}</RecommendedSizingLayout>
        </button>
      </>
    );
  } else {
    return null;
  }
};

interface RecommendedSizingOwnProps {
  gender: string | null;
  handleCalculateSizeClick: () => void;
  handleSetRecommendedFit: (predictedSize: string) => void;
  isOnDemandSizingModalOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  product: ProductDetailState;
  selectedSize?: string;
  location: Location;
  isDesktopView: boolean;
  isIncludeIcon?: boolean;
}
type PropsFromRedux = ConnectedProps<typeof connector>;
type RecommendedSizingProps = RecommendedSizingOwnProps & PropsFromRedux;

export const RecommendedSizing = (props: RecommendedSizingProps) => {
  const {
    predictedSize,
    selectedSize,
    onOpenModal,
    onCloseModal,
    handleSetRecommendedFit,
    gender,
    product: { detail },
    handleCalculateSizeClick,
    isOnDemandSizingModalOpen,
    location,
    isDesktopView,
    onDemandSizingCopy,
    isIncludeIcon,
    hasSizingPredictionLoaded
  } = props;

  if (!detail) {
    return null;
  }

  const { productId } = detail;

  return (
    <div>
      <LinkStyleRecommendedSizing
        hasSizingPredictionLoaded={hasSizingPredictionLoaded}
        predictedSize={predictedSize}
        selectedSize={selectedSize}
        onOpenModal={onOpenModal}
        isDesktopView={isDesktopView}
        onDemandSizingCopy={onDemandSizingCopy}
        isIncludeIcon={isIncludeIcon}
      />
      {isOnDemandSizingModalOpen && (
        <RecommendedSizeFinderModal
          productId={productId}
          gender={gender}
          onSetRecommendedFit={handleSetRecommendedFit}
          onClose={onCloseModal}
          location={location}
          handleCalculateSizeClick={handleCalculateSizeClick}
          isOpen={isOnDemandSizingModalOpen}
          predictedSize={predictedSize}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    headerFooter: {
      content: {
        Global: { slotData: { onDemandSizingCopy } } = {
          slotData: { onDemandSizingCopy: '' }
        }
      } = {}
    }
  } = state;
  return {
    hasSizingPredictionLoaded: selectHasSizingPredictionLoaded(state),
    predictedSize: selectPredictedSize(state),
    onDemandSizingCopy
  };
};

const connector = connect(mapStateToProps);

const ConnectedRecommendedSizing = connector(RecommendedSizing);

export default withErrorBoundary('ConnectedRecommendedSizing', ConnectedRecommendedSizing);

export const API_ERROR = 'API_ERROR';
export const CART_RESET_STATE = 'CART_RESET_STATE';
export const RECEIVE_FIELD_VALIDATION_ERRORS = 'RECEIVE_FIELD_VALIDATION_ERRORS';
export const RECEIVE_PIN = 'RECEIVE_PIN';
export const PIN_IS_VALID = 'PIN_IS_VALID';
export const RECEIVE_TRACKING_NUMBER = 'RECEIVE_TRACKING_NUMBER';
export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_AND_DEFER_SEARCH_RESPONSE = 'RECEIVE_AND_DEFER_SEARCH_RESPONSE';
export const RECEIVE_SEARCH_RESPONSE = 'RECEIVE_SEARCH_RESPONSE';
export const REQUEST_PRODUCT_DETAIL = 'REQUEST_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_DETAIL = 'RECEIVE_PRODUCT_DETAIL';
export const REQUEST_PRODUCT_REVIEWS = 'REQUEST_PRODUCT_REVIEWS';
export const REQUEST_SEARCH_REVIEWS = 'REQUEST_SEARCH_REVIEWS';
export const RECEIVE_SEARCH_REVIEWS = 'RECEIVE_SEARCH_REVIEWS';
export const EXIT_SEARCH_REVIEWS = 'EXIT_SEARCH_REVIEWS';
export const RECEIVE_PRODUCT_REVIEWS = 'RECEIVE_PRODUCT_REVIEWS';
export const SHOW_REVIEW_GALLERY_MODAL = 'SHOW_REVIEW_GALLERY_MODAL';
export const HIDE_REVIEW_GALLERY_MODAL = 'HIDE_REVIEW_GALLERY_MODAL';
export const REQUEST_PRODUCT_REVIEWS_WITH_MEDIA = 'REQUEST_PRODUCT_REVIEWS_WITH_MEDIA';
export const RECEIVE_PRODUCT_REVIEWS_WITH_MEDIA = 'RECEIVE_PRODUCT_REVIEWS_WITH_MEDIA';
export const REQUEST_UPVOTE_REVIEW = 'REQUEST_UPVOTE_REVIEW';
export const RECEIVE_UPVOTE_REVIEW_SUCCESS = 'RECEIVE_UPVOTE_REVIEW_SUCCESS';
export const RECEIVE_UPVOTE_REVIEW_FAILURE = 'RECEIVE_UPVOTE_REVIEW_FAILURE';
export const RECEIVE_REVIEWS_SUMMARIZATION_RENDERED = 'RECEIVE_REVIEW_SUMMARIZATION_RENDERED';
export const UPDATE_ADD_REVIEW_SUMMARY = 'UPDATE_ADD_REVIEW_SUMMARY';
export const UPDATE_ADD_REVIEW_RATING = 'UPDATE_ADD_REVIEW_RATING';
export const UPDATE_ADD_REVIEW_FIT = 'UPDATE_ADD_REVIEW_FIT';
export const UPDATE_ADD_REVIEW_ABOUT = 'UPDATE_ADD_REVIEW_ABOUT';
export const RECEIVE_SUBMIT_ADD_REVIEW = 'RECEIVE_SUBMIT_ADD_REVIEW';
export const REQUEST_SUBMIT_ADD_REVIEW = 'REQUEST_SUBMIT_ADD_REVIEW';
export const RECEIVE_ADD_REVIEW_MEDIA_UPLOAD = 'RECEIVE_ADD_REVIEW_MEDIA_UPLOAD';
export const REQUEST_ADD_REVIEW_MEDIA_UPLOAD = 'REQUEST_ADD_REVIEW_MEDIA_UPLOAD';
export const RESET_ADD_REVIEW = 'RESET_ADD_REVIEW';
export const ADD_REVIEW_FORM_VALIDATION = 'ADD_REVIEW_FORM_VALIDATION';
export const REQUEST_SIMILAR_STYLES = 'REQUEST_SIMILAR_STYLES';
export const RECEIVE_SIMILAR_STYLES = 'RECEIVE_SIMILAR_STYLES';
export const FETCH_RECOS = 'FETCH_RECOS';
export const RECEIVE_RECOS = 'RECEIVE_RECOS'; // janus recos
export const RECEIVE_RESET_LANDING_PAGE_INFO = 'RECEIVE_RESET_LANDING_PAGE_INFO';
export const REQUEST_LANDING_PAGE_INFO = 'REQUEST_LANDING_PAGE_INFO';
export const RECEIVE_LANDING_PAGE_INFO = 'RECEIVE_LANDING_PAGE_INFO';
export const SET_LANDING_PAGE_CONTENT = 'SET_LANDING_PAGE_DATA';
export const TOGGLE_EASYFLOW_MODAL = 'TOGGLE_EASYFLOW_MODAL';
export const SET_IP_RESTRICTED_STATUS = 'SET_IP_RESTRICTED_STATUS';
export const REQUEST_SYMPHONY_PREVIEW_INFO = 'REQUEST_SYMPHONY_PREVIEW_INFO';
export const RECEIVE_SYMPHONY_PREVIEW_INFO = 'RECEIVE_SYMPHONY_PREVIEW_INFO';
export const REQUEST_TAXONOMY_BRAND_PAGE_INFO = 'REQUEST_TAXONOMY_BRAND_PAGE_INFO';
export const RECEIVE_TAXONOMY_BRAND_PAGE_INFO = 'RECEIVE_TAXONOMY_BRAND_PAGE_INFO';
export const SET_PAGE_CONTENT_DYNAMIC_VALUES = 'SET_PAGE_CONTENT_DYNAMIC_VALUES';
export const TOGGLE_SELECTED_FACET_GROUP = 'TOGGLE_SELECTED_FACET_GROUP';
export const TOGGLE_SELECTED_FACET = 'TOGGLE_SELECTED_FACET';
export const FACET_URL_PARAMS = 'FACET_URL_PARAMS';
export const FACET_URL_PATH = 'FACET_URL_PATH';
export const UPDATE_SORT = 'UPDATE_SORT';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const RENDER_ERROR = 'RENDER_ERROR';
export const TOGGLE_FACETS = 'TOGGLE_FACETS';
export const TOGGLE_OOS_BUTTON = 'TOGGLE_OOS_BUTTON';
export const REQUEST_CART_UPDATE = 'REQUEST_CART_UPDATE';
export const RECEIVE_CART_ITEMS = 'RECEIVE_CART_ITEMS';
export const RECEIVE_CART_PROMOS = 'RECEIVE_CART_PROMOS';
export const RECEIVE_CART_RECOS = 'RECEIVE_CART_RECOS'; // janus recos on cart/cart modal/cart upsell
export const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT';
export const REQUEST_CART_COUNT = 'REQUEST_CART_COUNT';
export const CART_CHANGE_FAILURE = 'CART_CHANGE_FAILURE';
export const CART_ERROR = 'CART_ERROR';
export const CART_IS_LOADING = 'CART_IS_LOADING';
export const SET_CART_LOADED = 'SET_CART_LOADED';
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL';
export const SET_ITEM_NOTIFICATION = 'SET_ITEM_NOTIFICATION';
export const ROLLBACK_FILTERS = 'ROLLBACK_FILTERS';
export const PRODUCT_AGE_GROUP_CHANGED = 'PRODUCT_AGE_GROUP_CHANGED';
export const PRODUCT_GENDER_CHANGED = 'PRODUCT_GENDER_CHANGED';
export const PRODUCT_SINGLE_SHOE_SIDE_CHANGED = 'PRODUCT_SINGLE_SHOE_SIDE_CHANGED';
export const PRODUCT_SIZE_RANGE_CHANGED = 'PRODUCT_SIZE_RANGE_CHANGED';
export const PRODUCT_SIZE_UNIT_CHANGED = 'PRODUCT_SIZE_UNIT_CHANGED';
export const PRODUCT_SIZE_CHANGED = 'PRODUCT_SIZE_CHANGED';
export const PRODUCT_COLOR_CHANGED = 'PRODUCT_COLOR_CHANGED';
export const PRODUCT_DESCRIPTION_TOGGLE = 'PRODUCT_DESCRIPTION_TOGGLE';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const DROP_FLOW_START = 'DROP_FLOW_START';
export const DROP_FLOW_END = 'DROP_FLOW_END';
export const LOAD_PIXEL_SERVER = 'LOAD_PIXEL_SERVER';
export const SET_SHOULD_FIRE_ORDER_CONFIRMATION_PIXEL = 'SET_SHOULD_FIRE_ORDER_CONFIRMATION_PIXEL';
export const CLEAR_STORED_MICROSOFT_UET_EVENTS = 'CLEAR_STORED_MICROSOFT_UET_EVENTS';
export const SET_MICROSOFT_UET_TAG_LOADED = 'SET_MICROSOFT_UET_TAG_LOADED';
export const STORE_MICROSOFT_UET_EVENT = 'STORE_MICROSOFT_UET_EVENT';
export const VALIDATE_DIMENSIONS = 'VALIDATE_DIMENSIONS';
export const UPDATE_MAILING_ADDRESS_OR_NAME = 'UPDATE_MAILING_ADDRESS_OR_NAME';
export const UPDATE_SHIPPING_PHONE = 'UPDATE_SHIPPING_PHONE';
export const RESET_SHIPPING_FORM = 'RESET_SHIPPING_FORM';
export const RECEIVE_CHANGE_SHIPPING_ADDRESS_ERROR = 'RECEIVE_CHANGE_SHIPPING_ADDRESS_ERROR';
export const RECEIVE_SHIPPING_INFO_BY_ID = 'RECEIVE_SHIPPING_INFO_BY_ID';
export const REQUEST_SHIPPING_INFO = 'REQUEST_SHIPPING_INFO';
export const RECEIVE_SHIPPING_INFO = 'RECEIVE_SHIPPING_INFO';
export const REQUEST_ACCOUNT_INFO = 'REQUEST_ACCOUNT_INFO';
export const RECEIVE_ACCOUNT_INFO = 'RECEIVE_ACCOUNT_INFO';
export const REQUEST_PAYMENT_INFO = 'REQUEST_PAYMENT_INFO';
export const RECEIVE_PAYMENT_INFO = 'RECEIVE_PAYMENT_INFO';
export const RECEIVE_PAYMENT_INFO_BY_ID = 'RECEIVE_PAYMENT_INFO_BY_ID';
export const RESET_SAVED_PAYMENT_INFO = 'RESET_SAVED_PAYMENT_INFO';
export const REQUEST_SUBSCRIPTIONS_INFO = 'REQUEST_SUBSCRIPTIONS_INFO';
export const RECEIVE_SUBSCRIPTIONS_INFO = 'RECEIVE_SUBSCRIPTIONS_INFO';
export const RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA = 'RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA';
export const RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE = 'RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE';
export const SUBSCRIPTION_LIST_TOGGLED = 'SUBSCRIPTION_LIST_TOGGLED';
export const SUBSCRIPTION_BRAND_TOGGLED = 'SUBSCRIPTION_BRAND_TOGGLED';
export const SUBSCRIPTION_STOCK_TOGGLED = 'SUBSCRIPTION_STOCK_TOGGLED';
export const SUBSCRIPTION_UPCOMING_STYLE_TOGGLED = 'SUBSCRIPTION_UPCOMING_STYLE_TOGGLED';
export const CLEAR_HEARTS = 'CLEAR_HEARTS';
export const CLEAR_HEART_LIST = 'CLEAR_HEART_LIST';
export const RECEIVE_HEARTS = 'RECEIVE_HEARTS';
export const RECEIVE_HEARTS_IDS = 'RECEIVE_HEARTS_IDS';
export const ADD_OOS_ITEM = 'ADD_OOS_ITEM';
export const REMOVE_FROM_HEART_ID_LIST = 'REMOVE_FROM_HEART_ID_LIST';
export const ADD_TO_HEART_ID_LIST = 'ADD_TO_HEART_ID_LIST';
export const FETCH_LIST_DATA = 'FETCH_LIST_DATA';
export const NEW_COLLECTION_PRODUCT = 'NEW_COLLECTION_PRODUCT';
export const REMOVE_NEW_COLLECTION_PRODUCT = 'REMOVE_NEW_COLLECTION_PRODUCT';
export const RECEIVE_LIST_INFO = 'RECEIVE_LIST_INFO';
export const RECEIVE_ALL_LISTS = 'RECEIVE_ALL_LISTS';
export const RECEIVE_LIST_HEARTS = 'RECEIVE_LIST_HEARTS';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const REMOVE_HEARTS = 'REMOVE_HEARTS';
export const RECEIVE_SPECIFIC_ITEMID_LISTS = 'RECEIVE_SPECIFIC_ITEMID_LISTS';
export const ORDER_CLEAR_SELECTED_ITEMS = 'ORDER_CLEAR_SELECTED_ITEMS';
export const UPDATE_CANCELLATION_REASON = 'UPDATE_CANCELLATION_REASON';
export const REQUEST_CONFIRM_CANCELLATION = 'REQUEST_CONFIRM_CANCELLATION';
export const RECEIVE_CONFIRM_CANCELLATION = 'RECEIVE_CONFIRM_CANCELLATION';
export const RECEIVE_CONFIRM_CANCELLATION_ERROR = 'RECEIVE_CONFIRM_CANCELLATION_ERROR';
export const UPDATE_SEARCH_LAYOUT = 'UPDATE_SEARCH_LAYOUT';
export const RECEIVE_BRAND_NOTIFY_CONFIRMATION = 'RECEIVE_BRAND_NOTIFY_CONFIRMATION';
export const SERIALIZE_STATE = 'SERIALIZE_STATE';
export const UPDATE_ENVIRONMENT_CONFIG = 'UPDATE_ENVIRONMENT_CONFIG';
export const REDIRECT = 'REDIRECT';
export const SET_COOKIE = 'SET_COOKIE';
export const REMOVE_COOKIE = 'REMOVE_COOKIE';
export const SET_FACET_CHOSEN = 'SET_FACET_CHOSEN';
export const RESET_FACET_GROUP = 'RESET_FACET_GROUP';
export const UPDATE_ORIGINAL_TERM = 'UPDATE_ORIGINAL_TERM';
export const SHOW_SELECT_SIZE_TOOLTIP = 'SHOW_SELECT_SIZE_TOOLTIP';
export const HIDE_SELECT_SIZE_TOOLTIP = 'HIDE_SELECT_SIZE_TOOLTIP';
export const HIGHLIGHT_SELECT_SIZE_TOOLTIP = 'HIGHLIGHT_SELECT_SIZE_TOOLTIP';
export const UNHIGHLIGHT_SELECT_SIZE_TOOLTIP = 'UNHIGHLIGHT_SELECT_SIZE_TOOLTIP';
export const ASK_DATA_ERROR = 'ASK_DATA_ERROR';
export const CHANGE_VOTE_ON_ASK_ITEM = 'CHANGE_VOTE_ON_ASK_ITEM';
export const MY_ASK_VOTES_ERROR = 'MY_ASK_VOTES_ERROR';
export const NEW_ASK_ITEM = 'NEW_ASK_ITEM';
export const RECEIVE_ASK_DATA = 'RECEIVE_ASK_DATA';
export const RECEIVE_MY_ASK_VOTES = 'RECEIVE_MY_ASK_VOTES';
export const REPORT_ASK_ITEM = 'REPORT_ASK_ITEM';
export const SET_ANSWERING_ASK_QUESTION = 'SET_ANSWERING_ASK_QUESTION';
export const SET_ASK_QUESTIONS_COLLAPSED = 'SET_ASK_QUESTIONS_COLLAPSED';
export const SET_ASK_ANSWERS_COLLAPSED = 'SET_ASK_ANSWERS_COLLAPSED';
export const SET_VIEWING_ASK_QUESTIONS_DESKTOP = 'SET_VIEWING_ASK_QUESTIONS_DESKTOP';
export const SET_ASK_HMD_SURVEY_DATA = 'SET_ASK_HMD_SURVEY_DATA';
export const RESET_STATE = 'RESET_STATE';
export const RECEIVE_SIZING_PREDICTION_SUCCESS = 'RECEIVE_SIZING_PREDICTION_SUCCESS';
export const RECEIVE_SIZING_PREDICTION_FAILURE = 'RECEIVE_SIZING_PREDICTION_FAILURE';
export const RECEIVE_SIZING_PREDICTION_FINISHED = 'RECEIVE_SIZING_PREDICTION_FINISHED';
export const RECEIVE_GENERIC_SIZING_BIAS = 'RECEIVE_GENERIC_SIZING_BIAS';
export const RECEIVE_EGC_DATA = 'RECEIVE_EGC_DATA';
export const SET_EGC_DESIGN = 'SET_EGC_DESIGN';
export const REQUEST_SUPPORTED_BRANDS = 'REQUEST_SUPPORTED_BRANDS';
export const RECEIVE_SUPPORTED_BRANDS_SUCCESS = 'RECEIVE_SUPPORTED_BRANDS_SUCCESS';
export const RECEIVE_SUPPORTED_BRANDS_FAILURE = 'RECEIVE_SUPPORTED_BRANDS_FAILURE';
export const REQUEST_BRAND_LIST = 'REQUEST_BRAND_LIST';
export const RECEIVE_BRAND_LIST_SUCCESS = 'RECEIVE_BRAND_LIST_SUCCESS';
export const RECEIVE_BRAND_LIST_FAILURE = 'RECEIVE_BRAND_LIST_FAILURE';
export const REQUEST_ON_DEMAND_SIZING_PREDICTION = 'REQUEST_ON_DEMAND_SIZING_PREDICTION';
export const RECEIVE_ON_DEMAND_SIZING_PREDICTION_SUCCESS = 'RECEIVE_ON_DEMAND_SIZING_PREDICTION_SUCCESS';
export const RECEIVE_ON_DEMAND_SIZING_PREDICTION_FAILURE = 'RECEIVE_ON_DEMAND_SIZING_PREDICTION_FAILURE';
export const SET_RECOMMENDED_FIT = 'SET_RECOMMENDED_FIT';
export const SET_MODAL_VIEW = 'SET_MODAL_VIEW';
export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
export const UPDATE_PRODUCT_SIZE = 'UPDATE_PRODUCT_SIZE';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const RECEIVE_BRAND_PROMO = 'RECEIVE_BRAND_PROMO';
export const RECEIVE_PDP_SYMPHONY_COMPONENTS = 'RECEIVE_PDP_SYMPHONY_COMPONENTS';
export const REQUEST_PDP_SYMPHONY_COMPONENTS = 'REQUEST_PDP_SYMPHONY_COMPONENTS';
export const RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS = 'RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS';
export const REQUEST_PDP_STORY_SYMPHONY_COMPONENTS = 'REQUEST_PDP_STORY_SYMPHONY_COMPONENTS';
export const RECEIVE_CUSTOMER_AUTH_DETAILS = 'RECEIVE_CUSTOMER_AUTH_DETAILS';
export const RECEIVE_SEARCH_RECOS = 'RECEIVE_SEARCH_RECOS';
export const RECEIVE_SEARCH_INLINE_RECOS = 'RECEIVE_SEARCH_INLINE_RECOS';
export const CLEAR_SEARCH_INLINE_RECOS = 'CLEAR_SEARCH_INLINE_RECOS';
export const ROUTE_UPDATE_COMPLETE = 'ROUTE_UPDATE_COMPLETE';
export const SET_SEARCH_OOS_MESSAGING = 'SET_SEARCH_OOS_MESSAGING';
export const UPDATE_PAYMENT_FULL_NAME = 'UPDATE_PAYMENT_FULL_NAME';
export const UPDATE_PAYMENT_CARD_NUMBER = 'UPDATE_PAYMENT_CARD_NUMBER';
export const UPDATE_PAYMENT_EXPIRATION_MONTH = 'UPDATE_PAYMENT_EXPIRATION_MONTH';
export const UPDATE_PAYMENT_EXPIRATION_YEAR = 'UPDATE_PAYMENT_EXPIRATION_YEAR';
export const UPDATE_CVV = 'UPDATE_CVV';
export const UPDATE_PAYMENT_BILLING_ADDRESS = 'UPDATE_PAYMENT_BILLING_ADDRESS';
export const UPDATE_IS_PAYMENT_PRIMARY = 'UPDATE_IS_PAYMENT_PRIMARY';
export const RECEIVE_UPDATE_PAYMENT_INFO_ERROR = 'RECEIVE_UPDATE_PAYMENT_INFO_ERROR';
export const RESET_PAYMENT_FORM = 'RESET_PAYMENT_FORM';
export const REQUEST_GIFT_CARD_BALANCE = 'REQUEST_GIFT_CARD_BALANCE';
export const RECEIVE_GIFT_CARD_BALANCE = 'RECEIVE_GIFT_CARD_BALANCE';
export const REQUEST_REDEEM_GIFT_CARD = 'REQUEST_REDEEM_GIFT_CARD';
export const RECEIVE_REDEEM_GIFT_CARD_SUCCESS = 'RECEIVE_REDEEM_GIFT_CARD_SUCCESS';
export const RECEIVE_REDEEM_GIFT_CARD_FAILURE = 'RECEIVE_REDEEM_GIFT_CARD_FAILURE';
export const UPDATE_GIFT_CARD_REDEEM_CODE = 'UPDATE_GIFT_CARD_REDEEM_CODE';
export const STORE_HOLMES = 'STORE_HOLMES';
export const HOLMES_DETERMINED = 'HOLMES_DETERMINED';
export const STORE_ZFC_SESSION_ID = 'STORE_ZFC_SESSION_ID';
export const PRODUCT_SWATCH_CHANGE = 'PRODUCT_SWATCH_CHANGE';
export const CHECKOUT_UPDATED_QUANTITY = 'CHECKOUT_UPDATED_QUANTITY';
export const FORCE_ASSIGN_NEXT_SHIP_OPTION = 'FORCE_ASSIGN_NEXT_SHIP_OPTION';
export const SET_URL_UPDATED = 'SET_URL_UPDATED';
export const STORE_EVENT_IN_QUEUE = 'STORE_EVENT_QUEUE';
export const CLEAR_EVENT_QUEUE = 'CLEAR_EVENT_QUEUE';
export const RECEIVE_PRODUCT_RELATIONS = 'RECEIVE_PRODUCT_RELATIONS';
export const REQUEST_LOWEST_PRICES = 'REQUEST_LOWEST_PRICES';
export const RECEIVE_LOWEST_PRICES = 'RECEIVE_LOWEST_PRICES';

// product comparison
export const REQUEST_PRODUCT_COMPARISON_TABLE = 'REQUEST_PRODUCT_COMPARISON_TABLE';
export const RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS = 'RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS';
export const RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE = 'RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE';
export const RESET_PRODUCT_COMPARISON_TABLE = 'RESET_PRODUCT_COMPARISON_TABLE';
export const ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS = 'ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS';

// debugging & QA
export const RESET_ALL_DEBUG_FLAGS = 'RESET_ALL_DEBUG_FLAGS';
export const RESET_DEBUG_FLAG = 'RESET_DEBUG_FLAG';
export const SET_DEBUG_FLAG = 'SET_DEBUG_FLAG';

// feedback
export const FEEDBACK_CLICK = 'FEEDBACK_CLICK';

// personalized search
export const UPDATE_BEST_FOR_YOU = 'UPDATE_BEST_FOR_YOU';
export const TOGGLE_PERSONALIZED_SIZE = 'TOGGLE_PERSONALIZED_SIZE';
export const REMOVE_PERSONALIZED_SIZE = 'REMOVE_PERSONALIZED_SIZE';
export const UPDATE_SAVED_FILTERS = 'UPDATE_SAVED_FILTERS';
export const TOGGLE_SAVED_FILTERS = 'TOGGLE_SAVED_FILTERS';
export const CLEAR_SAVED_FILTERS = 'CLEAR_SAVED_FILTERS';
export const TOGGLE_SIZING_FACET_GROUP = 'TOGGLE_SIZING_FACET_GROUP';
export const TOGGLE_FACET_GROUP_SHOW_MORE = 'TOGGLE_FACET_GROUP_SHOW_MORE';

// myaccount cancel or return item selection
export const SELECT_ITEM_FOR_CANCELLATION_OR_RETURN = 'SELECT_ITEM_FOR_CANCELLATION_OR_RETURN';
export const SET_ITEMS_FOR_EXCHANGE_OR_RETURN = 'SET_ITEMS_FOR_EXCHANGE_OR_RETURN';
export const REMOVE_ITEM_FOR_CANCELLATION_OR_RETURN = 'REMOVE_ITEM_FOR_CANCELLATION_OR_RETURN';
export const RESET_CANCEL_OR_RETURN_ITEMS = 'RESET_CANCEL_OR_RETURN_ITEMS';
export const SET_PREVIOUS_ORDER_ACTION = 'SET_PREVIOUS_ORDER_ACTION';
export const RESTORE_ACCOUNT_STATE = 'RESTORE_ACCOUNT_STATE';

// myacount rewards
export const RECEIVE_REWARDS_TERMS = 'RECEIVE_REWARDS_TERMS';
export const REQUEST_SIGNUP_FOR_REWARDS = 'REQUEST_SIGNUP_FOR_REWARDS';
export const RECEIVE_SIGNUP_FOR_REWARDS = 'RECEIVE_SIGNUP_FOR_REWARDS';
export const REQUEST_REWARDS_INFO = 'REQUEST_REWARDS_INFO';
export const RECEIVE_REWARDS_INFO = 'RECEIVE_REWARDS_INFO';
export const REWARDS_DETERMINED = 'REWARDS_DETERMINED';
export const RECEIVE_REWARDS_ERROR = 'RECEIVE_REWARDS_ERROR';
export const CLEAR_REWARDS_SUCCESS = 'CLEAR_REWARDS_SUCCESS';
export const RECEIVE_PRIME_STATUS = 'RECEIVE_PRIME_STATUS';
export const RECEIVE_CUSTOMER_IS_BANNED = 'RECEIVE_CUSTOMER_IS_BANNED';
export const RECEIVE_EASY_FLOW_CONTENT = 'RECEIVE_EASY_FLOW_CONTENT';
export const RECEIVE_VIP_DASHBOARD_HEADER_CONTENT = 'RECEIVE_VIP_DASHBOARD_HEADER_CONTENT';

// myaccount rewards events
export const RR_CHANGE_REDEEM_AMOUNT = 'RR_CHANGE_REDEEM_AMOUNT';
export const RR_COMPONENT_VIEW = 'RR_COMPONENT_VIEW';

// myaccount ad preferences
export const RECEIVE_AD_PREFERENCES = 'RECEIVE_AD_PREFERENCES';
export const RECEIVE_AD_PREFERENCE_ERROR = 'RECEIVE_AD_PREFERENCE_ERROR';
export const SET_AD_CUSTOMER_INFO = 'AD_CUSTOMER_INFO';

// myaccount order detail page
export const REQUEST_ORDER_DETAILS = 'REQUEST_ORDER_DETAILS';
export const RECEIVE_ORDER_DETAILS = 'RECEIVE_ORDER_DETAILS';
export const CLEAR_VIEWED_ORDER = 'CLEAR_VIEWED_ORDER';
export const UPDATE_ORDER_WITH_TRACKING_INFO = 'UPDATE_ORDER_WITH_TRACKING_INFO';
export const RECEIVE_ACCOUNT_CONTENT = 'RECEIVE_ACCOUNT_CONTENT';
export const SET_IS_ORDER_ITEMS_UPDATING = 'SET_IS_ORDER_ITEMS_UPDATING';

// myaccount order care
export const REQUEST_UPDATE_ASSOCIATED_PAYMENT = 'REQUEST_UPDATE_ASSOCIATED_PAYMENT';
export const RECEIVE_UPDATE_PAYMENT_SUCCESS = 'RECEIVE_UPDATE_PAYMENT_SUCCESS';
export const REQUEST_UPDATE_UNASSOCIATED_PAYMENT = 'REQUEST_UPDATE_UNASSOCIATED_PAYMENT';
export const RECEIVE_UPDATE_PAYMENT_ERROR = 'RECEIVE_UPDATE_PAYMENT_ERROR';
export const REQUEST_ADD_NEW_PAYMENT = 'REQUEST_ADD_NEW_PAYMENT';
export const RECEIVE_ADD_NEW_PAYMENT_SUCCESS = 'RECEIVE_ADD_NEW_PAYMENT_SUCCESS';
export const RECEIVE_ADD_NEW_PAYMENT_ERROR = 'RECEIVE_ADD_NEW_PAYMENT_ERROR';
export const REQUEST_BILLING_ADDRESSES = 'REQUEST_BILLING_ADDRESSES';
export const RECEIVE_BILLING_ADDRESSES = 'RECEIVE_BILLING_ADDRESSES';
export const TOGGLE_ADD_NEW_BILLING_ADDRESS = 'TOGGLE_ADD_NEW_BILLING_ADDRESS';
export const RECEIVE_ADD_NEW_BILLING_ADDRESS_ERROR = 'RECEIVE_ADD_NEW_BILLING_ADDRESS_ERROR';
export const RECEIVE_SUGGESTED_ADDRESSES = 'RECEIVE_SUGGESTED_ADDRESSES';
export const SET_SHIPMENT_OPTIONS = 'SET_SHIPMENT_OPTIONS';
export const SET_SHIPMENT_OPTION_DETAILS = 'SET_SHIPMENT_OPTION_DETAILS';
export const SET_RETURN_SOURCE_ADDRESS = 'SET_RETURN_SOURCE_ADDRESS';
export const SET_IS_SHIPMENT_OPTIONS_ERROR = 'SET_IS_SHIPMENT_OPTIONS_ERROR';
export const SET_RETURN_ERROR = 'SET_RETURN_ERROR';
export const CLEAR_RETURN_ERROR = 'CLEAR_RETURN_ERROR';
export const SET_IS_SHIPMENT_OPTIONS_LOADING = 'SET_IS_SHIPMENT_OPTIONS_LOADING';
export const SET_IS_REPETITION_CONFLICT_ERROR = 'SET_IS_REPETITION_CONFLICT_ERROR';

// myaccount returns
export const RECEIVE_PRE_RETURN_INFO = 'RECEIVE_PRE_RETURN_INFO';
export const RECEIVE_POST_RETURN_INFO = 'RECEIVE_POST_RETURN_INFO';
export const STEP_SELECTION = 'STEP_SELECTION';
export const UPDATE_BOX_INFO = 'UPDATE_BOX_INFO';
export const RETURN_SUBMIT_INITIATE = 'RETURN_SUBMIT_INITIATE';
export const RETURN_SUBMIT_COMPLETE = 'RETURN_SUBMIT_COMPLETE';
export const RECEIVE_RETURN_LABEL_INFO = 'RECEIVE_RETURN_LABEL_INFO';
export const RECEIVE_RETURN_LABEL_PRODUCT_INFO = 'RECEIVE_RETURN_LABEL_PRODUCT_INFO';
export const RETURN_SUBMIT_ERROR = 'RETURN_SUBMIT_ERROR';
export const RETURN_PREVENTING_SUBMIT_ERROR = 'RETURN_PREVENTING_SUBMIT_ERROR';
export const RESET_RETURNS_IN_STATE = 'RESET_RETURNS_IN_STATE';
export const REQUEST_REDEMPTION_INCREMENTS = 'REQUEST_REDEMPTION_INCREMENTS';
export const SET_IS_EXCHANGE_ERROR_MESSAGE_DISPLAY = 'SET_IS_EXCHANGE_ERROR_MESSAGE_DISPLAY';
export const RETURN_CANCELLATION_REQUESTED = 'RETURN_CANCELLATION_REQUESTED';
export const RETURN_CANCELLATION_CONFIRMED = 'RETURN_CANCELLATION_CONFIRMED';
export const RETURN_CANCELLATION_ERROR = 'RETURN_CANCELLATION_ERROR';
export const RECEIVE_MULTIPLE_PRE_RETURN_INFO = 'RECEIVE_MULTIPLE_PRE_RETURN_INFO';
export const INITIATE_RETURN_BEGIN = 'INITIATE_RETURN_BEGIN';
export const RECEIVE_CONTRACT_RETURN_INFO = 'RECEIVE_CONTRACT_RETURN_INFO';
export const RECEIVE_RETURN_LABEL_INFO_START = 'RECEIVE_RETURN_LABEL_INFO_START';
export const RECEIVE_RETURN_LABEL_INFO_ERROR = 'RECEIVE_RETURN_LABEL_INFO_ERROR';
export const CLEAR_RETURN_STATES = 'CLEAR_RETURN_STATES';
export const CLEAR_POST_RETURN_INFO = 'CLEAR_POST_RETURN_INFO';
export const SUBMIT_RETURN_ERROR = 'SUBMIT_RETURN_ERROR';
export const CLEAR_SUBMIT_RETURN_ERROR = 'CLEAR_SUBMIT_RETURN_ERROR';

// myaccount shipment tracking
export const REQUEST_SHIPMENT_TRACKING = 'REQUEST_SHIPMENT_TRACKING';
export const RECEIVE_SHIPMENT_TRACKING = 'RECEIVE_SHIPMENT_TRACKING';
export const RESET_SHIPMENT_TRACKING = 'RESET_SHIPMENT_TRACKING';
export const RECEIVE_SHIPMENT_TRACKING_ERROR = 'RECEIVE_SHIPMENT_TRACKING_ERROR';

// myaccount order history
export const REQUEST_ORDER_HISTORY_SEARCH = 'REQUEST_ORDER_HISTORY_SEARCH';
export const RECEIVE_ORDER_HISTORY_SEARCH = 'RECIEVE_ORDER_HISTORY_SEARCH';
export const CANCEL_ORDER_HISTORY_SEARCH = 'CANCEL_ORDER_HISTORY_SEARCH';
export const ERROR_ORDER_HISTORY_SEARCH = 'ERROR_ORDER_HISTORY_SEARCH';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const TOGGLE_ORDER_COLLAPSE = 'TOGGLE_ORDER_COLLAPSE';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';

// notify oos / brand notify
export const RECEIVE_PRODUCT_ALL_SIZES = 'RECEIVE_PRODUCT_ALL_SIZES';
export const TOGGLE_PRODUCT_NOTIFY_MODAL = 'TOGGLE_PRODUCT_NOTIFY_MODAL';
export const SEND_PRODUCT_NOTIFY_SUBSCRIPTION = 'SEND_PRODUCT_NOTIFY_SUBSCRIPTION';
export const SEND_PRODUCT_NOTIFY_SUBSCRIPTION_COMPLETE = 'SEND_PRODUCT_NOTIFY_SUBSCRIPTION_COMPLETE';
export const TOGGLE_BRAND_NOTIFY_MODAL = 'TOGGLE_BRAND_NOTIFY_MODAL';

// report an error
export const SEND_REPORT_AN_ERROR = 'SEND_REPORT_AN_ERROR';
export const SEND_REPORT_AN_ERROR_COMPLETE = 'SEND_REPORT_AN_ERROR_COMPLETE';
export const RESET_REPORT_AN_ERROR = 'RESET_REPORT_AN_ERROR';
export const RECEIVE_REPORT_AN_ERROR_CAPTCHA = 'RECEIVE_REPORT_AN_ERROR_CAPTCHA';
export const REPORT_AN_ERROR_INVALID_CAPTCHA = 'REPORT_AN_ERROR_INVALID_CAPTCHA';

export const TOGGLE_HEARTING_LOGIN_MODAL = 'TOGGLE_HEARTING_LOGIN_MODAL';
export const ADD_ON_SALE = 'ADD_ON_SALE';

// headerfooter
export const SET_HF_DATA = 'SET_HF_DATA';
export const SET_HF_TOPBANNER_DATA = 'SET_HF_TOPBANNER_DATA';
export const SET_HF_SEARCH_TERM = 'SET_HF_SEARCH_TERM';
export const SET_HF_SEARCH_SUGGEST = 'SET_HF_SEARCH_SUGGEST';
export const SET_HF_SEARCH_SUGGEST_ACTIVE_INDEX = 'SET_HF_SEARCH_SUGGEST_ACTIVE_INDEX';
export const SET_HF_SEARCH_USER_TERMS = 'SET_HF_SEARCH_USER_TERMS';
export const SET_HF_SEARCH_ZERO_STATE = 'SET_HF_SEARCH_ZERO_STATE';
export const SET_HF_SEARCH_FILTER = 'SET_HF_SEARCH_FILTER';
export const SET_HF_SEARCH_REDIRECT_PREV_FILTER = 'SET_HF_SEARCH_REDIRECT_PREV_FILTER';
export const SET_HF_OPENED_NAV = 'SET_HF_OPENED_NAV';
export const SET_HF_OPENED_SUBNAV = 'SET_HF_OPENED_SUBNAV';
export const SET_HF_MOBILE_BREAKPOINT = 'SET_HF_MOBILE_BREAKPOINT';
export const SET_HF_MOBILE_NAV_POSITIONING = 'SET_HF_MOBILE_NAV_POSITIONING';
export const SET_HF_DESKTOP_NAV_POSITIONING = 'SET_HF_DESKTOP_NAV_POSITIONING';
export const SET_FOOTER_HISTORY_RECOS = 'SET_FOOTER_HISTORY_RECOS';
export const SET_HF_SIGN_UP_MODAL = 'SET_HF_SIGN_UP_MODAL';
export const SET_HF_FOOT_SUBSCRIBE_SUBMIT = 'SET_HF_FOOT_SUBSCRIBE_SUBMIT';
export const SET_HF_FOOT_SUBSCRIBE_ERROR = 'SET_HF_FOOT_SUBSCRIBE_ERROR';
export const SET_HF_FOOT_SUBSCRIBE_SUBMITTING = 'SET_HF_FOOT_SUBSCRIBE_SUBMITTING';
export const SET_HF_REMOTE = 'SET_HF_REMOTE';
export const SHOW_HF_TOP_BANNER = 'SHOW_HF_TOP_BANNER';
export const TOGGLE_HF = 'TOGGLE_HF';
export const MINIFY_HF = 'MINIFY_HF';
export const UPDATE_SEARCH_LIMIT = 'UPDATE_SEARCH_LIMIT';
export const TOGGLE_MOBILE_HEADER_EXPAND = 'TOGGLE_MOBILE_HEADER_EXPAND';
export const SET_FEDERATED_LOGIN_MODAL_VISIBILITY = 'SET_FEDERATED_LOGIN_MODAL_VISIBILITY';
export const SET_GLOBAL_BANNER_DATA = 'SET_GLOBAL_BANNER_DATA';
export const DISMISS_GLOBAL_BANNER = 'DISMISS_GLOBAL_BANNER';
export const ON_FEDERATED_LOGIN_MODAL_PAGE_VIEW = 'ON_FEDERATED_LOGIN_MODAL_PAGE_VIEW';
export const RECEIVE_REMOTE_HF_CONTENT = 'RECEIVE_REMOTE_HF_CONTENT';
export const SET_REWARDS_BENEFITS = 'SET_REWARDS_BENEFITS';
export const SET_ZAW_CONTENT_SYMPHONY_HEADER_DATA = 'SET_ZAW_CONTENT_SYMPHONY_HEADER_DATA';

// autoComplete
export const AUTOCOMPLETE_CLEAR = 'AUTOCOMPLETE_CLEAR';
export const AUTOCOMPLETE_UNREGISTER = 'AUTOCOMPLETE_UNREGISTER';
export const AUTOCOMPLETE_UPDATE = 'AUTOCOMPLETE_UPDATE';

// newsfeed
export const SET_RANDOM_NEWSFEED_WIDGET = 'SET_RANDOM_NEWSFEED_WIDGET';
export const SEND_NEWSFEED_IMPRESSION = 'SEND_NEWSFEED_IMPRESSION';
export const SEND_FITSURVEY_REPLY = 'SEND_FITSURVEY_REPLY';
export const SEND_NEWSFEED_DISMISSAL = 'SEND_NEWSFEED_DISMISSAL';
export const RECEIVE_EXPLICIT_FITS = 'RECEIVE_EXPLICIT_FITS';
export const RECEIVE_EXPLICIT_FIT = 'RECEIVE_EXPLICIT_FIT';
export const DELETE_EXPLICIT_FIT = 'DELETE_EXPLICIT_FIT';

// iframe
export const UPDATE_IFRAME_SHEERID_SOURCE = 'UPDATE_IFRAME_SHEERID_SOURCE';

// diamond
export const RECEIVE_ROUTE_DETAILS = 'RECEIVE_ROUTE_DETAILS';
export const REQUEST_ROUTE_DETAILS = 'REQUEST_ROUTE_DETAILS';
export const SET_REQUEST_INFORMATION = 'SET_REQUEST_INFORMATION';

// common
export const PAGE_TYPE_CHANGE = 'PAGE_TYPE_CHANGE';
export const CLIENT_VIEW_TRACKED = 'CLIENT_VIEW_TRACKED';
export const LOCATION_UPDATED = 'LOCATION_UPDATED';
export const LOCATION_INIT = 'LOCATION_INIT';

// event specific
export const EVENT_ON_DEMAND = 'EVENT_ON_DEMAND';
export const EVENT_SIZING_IMPRESSION = 'EVENT_SIZING_IMPRESSION';

// document meta and response data events
export const SET_RESPONSE_STATUS = 'SET_RESPONSE_STATUS';
export const SET_DOC_META_LP = 'SET_DOC_META_LP';
export const SET_DOC_META_PDP = 'SET_DOC_META_PDP';
export const SET_DOC_META_PRODUCT_REVIEWS = 'SET_DOC_META_PRODUCT_REVIEWS';
export const SET_DOC_META_WRITE_REVIEW = 'SET_DOC_META_WRITE_REVIEW';

export const LOAD_ZFC_SEO_DATA = 'LOAD_ZFC_SEO_DATA';

// blacklisted search
export const BLACKLISTED_SEARCH = 'BLACKLISTED_SEARCH';

export const PAGE_LOAD_EVENT = 'PAGE_LOAD_EVENT';

// Local Storage
export const LOAD_INITIAL_LOCAL_STORAGE = 'LOAD_INITIAL_LOCAL_STORAGE';
export const SET_LAST_SELECTED_SIZE = 'SET_LAST_SELECTED_SIZE';
export const SAVE_ODSP_DATA = 'SAVE_ODSP_DATA';

// Exchanges
export const RECEIVE_PRE_EXCHANGE_INFO = 'RECEIVE_PRE_EXCHANGE_INFO';
export const RECEIVE_POST_EXCHANGE_INFO = 'RECEIVE_POST_EXCHANGE_INFO';
export const EXCHANGE_SUBMIT_INITIATE = 'EXCHANGE_SUBMIT_INITIATE';
export const EXCHANGE_SUBMIT_COMPLETE = 'EXCHANGE_SUBMIT_COMPLETE';
export const EXCHANGE_SUBMIT_ERROR = 'EXCHANGE_SUBMIT_ERROR';
export const EXCHANGE_SET_NEW_ITEM_ASIN = 'EXCHANGE_SET_NEW_ITEM_ASIN';
export const EXCHANGE_SET_PRODUCT_BUNDLE_INFO = 'EXCHANGE_SET_PRODUCT_BUNDLE_INFO';
export const EXCHANGE_SET_INIT_EXCHANGE_DATA = 'EXCHANGE_SET_INIT_EXCHANGE_DATA';
export const EXCHANGE_GET_ORDER_ID_SUCCESS = 'EXCHANGE_GET_ORDER_ID_SUCCESS';
export const EXCHANGE_GET_ORDER_ID_ERROR = 'EXCHANGE_GET_ORDER_ID_ERROR';
export const EXCHANGE_SET_500_ERROR = 'EXCHANGE_SET_500_ERROR';
export const EXCHANGE_SELECT_ITEM = 'EXCHANGE_SELECT_ITEM';

// Ads
export const ADD_AD_TO_QUEUE = 'ADD_AD_TO_QUEUE';
export const RESET_AD_QUEUE = 'RESET_AD_QUEUE';
export const TRACK_MSFT_AD_IMPRESSIONS = 'TRACK_MSFT_AD_IMPRESSIONS';

// Outfit Product Recommendations
export const SET_OUTFIT_RECOS = 'SET_OUTFIT_RECOS';
export const SET_CURRENTLY_VIEWING_OUTFIT_RECO = 'SET_CURRENTLY_VIEWING_OUTFIT_RECO';
export const ADD_OUTFIT_PRODUCT_DETAILS = 'ADD_OUTFIT_PRODUCT_DETAILS';
export const ADD_IMAGE_DIMESIONS = 'ADD_IMAGE_DIMESIONS';
export const SET_SOURCE_PRODUCT_URL = 'SET_SOURCE_PRODUCT_URL';

// Quick subscription
export const QUICK_SUBSCRIPTION_SUCCESS = 'QUICK_SUBSCRIPTION_SUCCESS';
export const QUICK_SUBSCRIPTION_ERROR = 'QUICK_SUBSCRIPTION_ERROR';

// Launch Calendar
export const SET_LAUNCH_CALENDAR_RECENT_LAUNCHES = 'SET_LAUNCH_CALENDAR_RECENT_LAUNCHES';
export const SET_LAUNCH_CALENDAR_UPCOMING_STYLES = 'SET_LAUNCH_CALENDAR_UPCOMING_STYLES';
export const ADD_UPCOMING_STYLES_QUICK_VIEW_DATA = 'ADD_UPCOMING_STYLES_QUICK_VIEW_DATA';
export const SET_API_STATUS = 'SET_API_STATUS';

// ZAW
export const REQUEST_ZAW_PROMOTIONS = 'REQUEST_ZAW_PROMOTIONS';
export const RECEIVE_ZAW_PROMOTIONS = 'RECEIVE_ZAW_PROMOTIONS';
export const RESET_ZAW_STATE = 'RESET_ZAW_STATE';

// Badges
export const SET_BADGES_METADATA = 'SET_BADGES_METADATA';

// Features
export const FEATURE_FLAGS_LOADED = 'FEATURE_FLAGS_LOADED';
export const UNLEASH_BOOTSTRAP_LOADED = 'UNLEASH_BOOTSTRAP_LOADED';

'use client';

import { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-namespace
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { type ToggleProps } from './Toggle.types';
import { sizeVariants } from './Toggle.classes';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';

export const Toggle = forwardRef<HTMLDivElement, ToggleProps>((props: ToggleProps, ref) => {
  const { className, testId, options, defaultValue, size = 'base', onValueChange, ...forwardProps } = props;
  const { testId: getTestId } = useMartyContext();

  // Get the appropriate size classes
  const sizeClasses = sizeVariants[size];

  // Determine initial state
  const initialIndex = defaultValue !== undefined ? options.indexOf(defaultValue) : 0;

  // Single state for active index
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  // Track if user has made a selection
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  // Refs for measuring option widths
  const triggerRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [indicatorStyle, setIndicatorStyle] = useState({
    transform: 'translateX(0)',
    width: '0px'
  });

  // Handle value change
  const handleValueChange = useCallback(
    (valueId: string) => {
      // Convert the string value ID to a numeric index
      // The valueId is the index of the selected option in the options array
      const index = parseInt(valueId, 10);

      // Validate the parsed index:
      // - Ensure it's a valid number (not NaN)
      // - Ensure it's not negative
      // - Ensure it's within the bounds of the options array
      // If any validation fails, exit early without updating state
      if (isNaN(index) || index < 0 || index >= options.length) {
        return;
      }

      // Mark that user has interacted with the component
      if (!hasUserInteracted) {
        setHasUserInteracted(true);
      }

      setActiveIndex(index);
      // Always call onValueChange with the option value if it exists
      const selectedOption = options[index];
      if (selectedOption && onValueChange) {
        onValueChange(selectedOption);
      }
    },
    [options, onValueChange, hasUserInteracted]
  );

  // Use a single useLayoutEffect to handle both initial positioning and updates
  useLayoutEffect(() => {
    const trigger = triggerRefs.current[activeIndex];
    if (!trigger) {
      return;
    }

    // Calculate total width of all previous triggers
    let translateX = 0;
    for (let i = 0; i < activeIndex; i++) {
      translateX += triggerRefs.current[i]?.offsetWidth || 0;
    }

    setIndicatorStyle({
      transform: `translateX(${translateX}px)`,
      width: `${trigger.offsetWidth}px`
    });
  }, [activeIndex]);

  return (
    <div
      className={cn('relative inline-flex w-fit items-center rounded-full border-2 border-button-primary', sizeClasses.container, className)}
      data-test-id={getTestId(testId)}
      ref={ref}
      {...forwardProps}
    >
      <TabsPrimitive.Root value={String(activeIndex)} onValueChange={handleValueChange} defaultValue={String(initialIndex)} className="w-full">
        {/* Selection indicator */}
        <div
          className={cn(
            'absolute z-0 rounded-full bg-button-selected',
            hasUserInteracted && 'transition-all duration-300 ease-in-out',
            sizeClasses.indicator
          )}
          style={indicatorStyle}
        />

        <TabsPrimitive.List className="relative z-10 flex w-full items-center">
          {options.map((option, index) => {
            const isActive = index === activeIndex;
            return (
              <TabsPrimitive.Trigger
                key={index}
                value={String(index)}
                ref={el => {
                  triggerRefs.current[index] = el;
                }}
                className={cn(
                  'relative whitespace-nowrap rounded-full font-medium',
                  hasUserInteracted && 'transition-colors duration-300',
                  'flex items-center justify-center text-center',
                  sizeClasses.button,
                  isActive ? 'text-button-reversed' : 'text-button-primary',
                  'data-[state=active]:text-button-reversed data-[state=inactive]:text-button-primary'
                )}
              >
                {option}
              </TabsPrimitive.Trigger>
            );
          })}
        </TabsPrimitive.List>
      </TabsPrimitive.Root>
    </div>
  );
});

import queryString from 'query-string';
import { replace } from 'connected-react-router';

import {
  ADD_OOS_ITEM,
  ADD_TO_HEART_ID_LIST,
  CLEAR_HEART_LIST,
  CLEAR_HEARTS,
  DELETE_IMAGE,
  NEW_COLLECTION_PRODUCT,
  RECEIVE_ALL_LISTS,
  RECEIVE_HEARTS,
  RECEIVE_HEARTS_IDS,
  RECEIVE_LIST_HEARTS,
  RECEIVE_LIST_INFO,
  RECEIVE_SPECIFIC_ITEMID_LISTS,
  REMOVE_FROM_HEART_ID_LIST,
  REMOVE_HEARTS,
  REMOVE_NEW_COLLECTION_PRODUCT,
  TOGGLE_HEARTING_LOGIN_MODAL
} from 'constants/reduxActions';
import { ERROR_CUSTOMER_NOT_RECOGNIZED, FAVORITES_PATH_PREFIX, HEART_DEFAULT_LIST_ID } from 'constants/apis';
import { productBundle } from 'apis/cloudcatalog';
import { track } from 'apis/amethyst';
import {
  addSubItemId,
  DELETE_COLLECTION_LIST,
  evAddToCollections,
  evCreateCollectionClick,
  evRemoveFromCollections,
  SHARE_COLLECTION_LIST
} from 'events/favorites';
import { COLLECTIONS_LIST_PAGE } from 'constants/amethystPageTypes';
import { getAmethystPageType } from 'helpers/analytics';
import { authenticationErrorCatchHandlerFactory } from 'actions/errors';
import {
  addToList,
  createList,
  deleteImage,
  deleteList,
  getAllLists,
  getHeartList,
  getItemSubsetOnLists,
  getListInfo,
  getListOfIds,
  removeFromList,
  shareList,
  uploadImage
} from 'apis/account';
import { prependAppRoot } from 'history/AppRootUtils';
import {
  fetchApiNotAuthenticatedMiddleware,
  fetchErrorMiddleware,
  fetchErrorMiddlewareAllowedErrors,
  fetchErrorMiddlewareMaybeJson
} from 'middleware/fetchErrorMiddleware';
import { setSessionCookies } from 'actions/session';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import marketplace from 'cfg/marketplace.json';
import { trackError } from 'helpers/ErrorUtils';
import { selectAccountConfig } from 'selectors/environment';
import { COLLECTION_TYPE } from 'constants/amethystEnums';
import { parseIntOrNull } from 'helpers/HeartUtils';
const { hasHearting } = marketplace;

export function receiveHeartIdList(heartsStyleIds) {
  return {
    type: RECEIVE_HEARTS_IDS,
    heartsStyleIds
  };
}

export function removeHeartFromList(styleId) {
  return {
    type: REMOVE_FROM_HEART_ID_LIST,
    styleId
  };
}

export function addHeartToList(styleId) {
  return {
    type: ADD_TO_HEART_ID_LIST,
    styleId
  };
}

export function receiveHeartList(heartList, concat) {
  const { items, nextPageToken = null } = heartList;

  return {
    type: RECEIVE_HEARTS,
    hearts: items,
    nextPageToken,
    concat
  };
}

export function clearHearts() {
  return {
    type: CLEAR_HEARTS
  };
}

export function clearHeartList() {
  return {
    type: CLEAR_HEART_LIST
  };
}

export function receiveCollectionList(heartList, listId, concat) {
  const { items, nextPageToken = null } = heartList;

  return {
    type: RECEIVE_LIST_HEARTS,
    listId,
    hearts: items,
    nextPageToken,
    concat
  };
}

export function addOosItem({ itemId }) {
  return {
    type: ADD_OOS_ITEM,
    itemId
  };
}

export function receiveListInfo(list) {
  return {
    type: RECEIVE_LIST_INFO,
    list
  };
}

export function receiveAllLists(listsObject, concat) {
  const { lists, nextPageToken } = listsObject;
  return {
    type: RECEIVE_ALL_LISTS,
    concat,
    lists,
    collectionNextPageToken: nextPageToken
  };
}

export function receiveSpecificItemIdLists(itemIdLists) {
  return {
    type: RECEIVE_SPECIFIC_ITEMID_LISTS,
    itemIdLists
  };
}

export function removeImage(listId) {
  return {
    type: DELETE_IMAGE,
    listId
  };
}

export function removeHearts(itemIdsToRemove) {
  return dispatch => {
    dispatch({
      type: REMOVE_HEARTS,
      itemIdsToRemove
    });
  };
}

export function addProductToNewCollection(product) {
  return {
    type: NEW_COLLECTION_PRODUCT,
    product
  };
}

export function removeProductFromNewCollection() {
  return {
    type: REMOVE_NEW_COLLECTION_PRODUCT
  };
}

export function uploadImageData(imageData, call = uploadImage) {
  return (dispatch, getState) => {
    const accountConfig = selectAccountConfig(getState());

    return call(accountConfig, imageData)
      .then(fetchErrorMiddlewareMaybeJson)
      .catch(e => trackError('NON-FATAL', 'Could not upload image details.', e));
  };
}

export function deleteCollectionImage({ listId, imageLocation } = {}, call = deleteImage) {
  return (dispatch, getState) => {
    const accountConfig = selectAccountConfig(getState());

    return call(accountConfig, { listId, type: imageLocation })
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => {
        dispatch(removeImage(listId));
      })
      .catch(e => trackError('NON-FATAL', 'Could not delete image details.', e));
  };
}

export function fetchHeartList(
  { nextPageToken = null, concat = false, suppressAuthCatch = false, shareToken = undefined, listId = 'h.' } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);
    const { 'x-main': xMain } = cookies;

    if (!shareToken) {
      if (!cookies['x-main'] && !suppressAuthCatch) {
        return authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location));
      } else if (!xMain) {
        return;
      }
    }

    return heartList(accountConfig, { shareToken, listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(receiveHeartList(resp, concat));
        return resp;
      })
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function fetchCollectionList(
  { listId, nextPageToken = null, concat = false, suppressAuthCatch = false } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      hearts: { collections },
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    // return if already fetched and present in collections
    if (collections?.some(collection => collection.listId === listId)) {
      return;
    }

    return heartList(accountConfig, { listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(receiveCollectionList(resp, listId, concat));
        return resp;
      })
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function toggleHeartingLoginModal(open, id) {
  return {
    type: TOGGLE_HEARTING_LOGIN_MODAL,
    open,
    id
  };
}

export function heartProduct(
  {
    itemId = '',
    subItemId = '',
    listId = HEART_DEFAULT_LIST_ID,
    listType = '',
    merchantId = '',
    colorId = '',
    productId = '',
    price = 0,
    missingDimension = '',
    sourcePage = ''
  },
  callback,
  { add = addToList } = {}
) {
  // TODO ts remove undefined and make the interface Partial once this file is typed
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies: { 'x-main': xMain },
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);

    if (!xMain) {
      return dispatch(toggleHeartingLoginModal(true, itemId));
    }

    !!itemId && dispatch(addHeartToList(itemId)); // heart right away in the UI to prevent delay
    const itemIdOrSubItemId = subItemId ? { subItemId } : { itemId }; // Request can not contain both itemId and subItemId
    return add(accountConfig, { ...itemIdOrSubItemId, listId, merchantId })
      .then(res => {
        // If successful we get 200 w/ empty body
        if (res.status !== 200) {
          throw Error(res.statusText);
        } else {
          if (callback) {
            callback();
          }

          const amePayload = addSubItemId(
            {
              styleId: parseIntOrNull(itemId),
              collectionId: listId,
              collectionType: COLLECTION_TYPE[listType] ?? COLLECTION_TYPE.FAVORITES_LIST,
              productId: parseIntOrNull(productId),
              colorId: parseIntOrNull(colorId),
              price,
              sourcePage: sourcePage ? sourcePage : getAmethystPageType(pageType),
              incompleteAddToCollections: !!missingDimension,
              missingDimension: missingDimension || null
            },
            subItemId
          );

          track(() => [evAddToCollections, amePayload]);
        }
      })
      .catch(() => {
        dispatch(removeHeartFromList(itemId)); // unheart if call fails
        // x-main probably just invalid, let's have them login and try again
        return dispatch(toggleHeartingLoginModal(true, itemId));
      });
  };
}

export function unHeartProduct(
  {
    itemId = '',
    subItemId = '',
    listId = HEART_DEFAULT_LIST_ID,
    listType = '',
    merchantId = '',
    colorId = '',
    productId = '',
    price = 0,
    sourcePage = ''
  },
  callback,
  { remove = removeFromList } = {}
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);
    !!itemId && dispatch(removeHeartFromList(itemId)); // unheart right away in the UI to prevent delay

    return remove(accountConfig, { itemId, subItemId, listId, merchantId })
      .then(res => {
        // If successful we get 200 w/ empty body
        if (res.status !== 200) {
          throw Error(res.statusText);
        } else {
          if (callback) {
            callback();
          }

          const amePayload = addSubItemId(
            {
              styleId: parseIntOrNull(itemId),
              collectionId: listId,
              collectionType: COLLECTION_TYPE[listType] ?? COLLECTION_TYPE.FAVORITES_LIST,
              productId: parseIntOrNull(productId),
              colorId: parseIntOrNull(colorId),
              sourcePage: sourcePage ? sourcePage : getAmethystPageType(pageType),
              price
            },
            subItemId
          );

          track(() => [evRemoveFromCollections, amePayload]);
        }
      })
      .catch(() => {
        dispatch(addHeartToList(itemId)); // re-heart if call fails
        // x-main probably just invalid, let's have them login and try again
        return dispatch(toggleHeartingLoginModal(true, itemId));
      });
  };
}

export function getHearts(getHeartsList = getListOfIds, enabled = hasHearting) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: {
        location: { pathname, query }
      }
    } = state;
    const accountConfig = selectAccountConfig(state);
    const { heartOnLoad: itemId, heartOnLoadSub: subItemId } = query;

    if (!enabled || !cookies['x-main']) {
      return null;
    }
    return getHeartsList(accountConfig)
      .then(fetchErrorMiddleware)
      .then(res => {
        if (res && res.ids) {
          dispatch(receiveHeartIdList(res.ids));
        }

        if (itemId || subItemId) {
          const newQueryObject = {
            ...query,
            t: query.t ? decodeURIComponent(query.t) : undefined, // Avoid double encoding
            heartOnLoad: undefined,
            heartOnLoadSub: undefined
          };
          const newQueryString = queryString.stringify(newQueryObject);
          dispatch(replace(`${pathname}?${newQueryString}`));
          return dispatch(heartProduct({ itemId, subItemId }));
        }
      });
  };
}

export function getAmethystSourcePage(pageType) {
  let sourcePage = getAmethystPageType(pageType);
  if (sourcePage === 'FAVORITES_PAGE') {
    sourcePage = COLLECTIONS_LIST_PAGE;
  }
  return sourcePage;
}
export function createHeartList({ listTypeId, listName, listType }, doCreateList = createList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType }
    } = state;
    const sourcePage = getAmethystSourcePage(pageType);
    const accountConfig = selectAccountConfig(state);

    return doCreateList(accountConfig, { listTypeId, listName })
      .then(resp => {
        const eventData = {
          collectionName: listName,
          collectionId: listTypeId,
          collectionType: listType,
          sourcePage
        };
        track(() => [evCreateCollectionClick, eventData]);
        return resp;
      })
      .then(fetchErrorMiddleware);
  };
}

export function deleteHeartList(listTypeId, doDeleteList = deleteList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);

    const sourcePage = getAmethystSourcePage(pageType);
    return doDeleteList(accountConfig, listTypeId)
      .then(resp => {
        dispatch({
          type: DELETE_COLLECTION_LIST,
          collectionId: listTypeId,
          sourcePage
        });

        return resp;
      })
      .then(fetchErrorMiddlewareMaybeJson);
  };
}

export function fetchListInfo({ listId, shareToken }, listInfo = getListInfo) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return listInfo(accountConfig, { listId, shareToken }, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveListInfo(response));
        return response;
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function sharingList({ listId }, doShareList = shareList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return doShareList(accountConfig, listId)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function shareListEvent({ listId, shareToken }) {
  return (dispatch, getState) => {
    const {
      pageView: { pageType }
    } = getState();
    const sourcePage = getAmethystPageType(pageType);
    dispatch({
      type: SHARE_COLLECTION_LIST,
      collectionId: listId,
      shareToken,
      sourcePage
    });
  };
}

export function getListsForItemId({ itemId }, getLists = getItemSubsetOnLists) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    return getLists(accountConfig, { itemId }, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveSpecificItemIdLists(response));
        return response;
      });
  };
}

export function getItemsForListId({ listId }, getItems = getListOfIds) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    if (!cookies['x-main']) {
      return null;
    }

    return getItems(accountConfig, listId, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        if (resp?.ids) {
          dispatch(receiveHeartIdList(resp.ids));
        }
        return resp;
      });
  };
}

export function getLists({ shareToken, listId, nextPageToken = null, concat = false } = {}, getLists = getAllLists) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    if (!shareToken && !cookies['x-main']) {
      return dispatch(toggleHeartingLoginModal(true));
    }

    return getLists(accountConfig, { shareToken, listId, nextPageToken }, cookies)
      .then(fetchErrorMiddlewareAllowedErrors([403]))
      .then(response => {
        if (response.message === ERROR_CUSTOMER_NOT_RECOGNIZED) {
          dispatch(toggleHeartingLoginModal(true));
        } else {
          dispatch(receiveAllLists(response, concat));
        }
      })
      .catch(e => trackError('NON-FATAL', 'Could not load hearts getLists.', e));
  };
}

export function fetchAllCollectionData(nextPageToken = null, concat = false) {
  return (dispatch, getState) =>
    dispatch(getLists({ nextPageToken, concat })).then(() => {
      const {
        hearts: { lists, collections }
      } = getState();
      lists?.forEach(({ listId }) => {
        if (!collections.some(collection => collection.listId === listId)) {
          fetchAllCollectionList(listId, dispatch);
        }
      });
    });
}

const fetchAllCollectionList = async (listId, dispatch) => {
  let items = [];
  let moreItems, nextPageToken;
  do {
    ({ items: moreItems, nextPageToken } = await dispatch(fetchCollectionListData({ listId, nextPageToken })));
    items = items.concat(moreItems);
  } while (nextPageToken);
  dispatch(receiveCollectionList({ items, nextPageToken }, listId, false));
};

export function fetchCollectionListData(
  { listId, nextPageToken = null, suppressAuthCatch = false } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return heartList(accountConfig, { listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function fetchProductStyleDetails(styleId, merchantId, fetchProductDetails = productBundle, entireProduct = false, includeSizing = false) {
  return (dispatch, getState) => {
    const {
      environmentConfig: {
        api: { cloudcatalog }
      }
    } = getState();

    return fetchProductDetails(cloudcatalog, { styleId, entireProduct, includeBrand: false, includeSizing })
      .then(fetchErrorMiddleware)
      .then(response => {
        const { brandName, productName, productId, styles } = response.product[0];
        const style = styles.find(styleDetail => styleDetail.styleId === styleId);
        const defaultStyle = { color: 'N/A', images: [], price: 'N/A', stocks: [] };
        const { color, images, price, colorId, stocks } = style || defaultStyle;
        const { imageId } = images.find(image => image.type === 'MAIN') || { imageId: null };
        const quantity = stocks.reduce((previousValue, currentValue) => previousValue + Number(currentValue.onHand), 0);
        const productStyleDetails = {
          styleId,
          brandName,
          productId,
          productName,
          colorId,
          color,
          price,
          imageId,
          itemId: styleId,
          quantity,
          merchantId
        };
        dispatch(addProductToNewCollection(productStyleDetails));
      });
  };
}

import { stringify } from 'query-string';

import timedFetch from 'middleware/timedFetch';
import { setSessionRequestedHeaders } from 'actions/session';
import { cookieObjectToString } from 'helpers/Cookie';
import type { CloudCatalogBrand, ProductBundleOptions, ProductBundleResponse } from 'types/cloudCatalog';
import type { Cookies } from 'types/cookies';

export interface CloudCatalogOptions {
  url: string;
  v2url: string;
  siteId: number;
  subsiteId: number;
}

const BASE_INCLUDES = ['preferredSubsite', 'hardLaunchDate', 'taxonomyAttributes'];
const IN_STOCK_INCLUDES = BASE_INCLUDES.concat(['drop', 'finalSale']);

interface headerObj {
  [key: string]: string;
}

interface fetchOptsObj {
  method: string;
  credentials: string;
  headers: headerObj;
  signal?: AbortSignal;
}

export const makeFetchOptions = function (cookies: Cookies, abortController?: AbortController) {
  const config: fetchOptsObj = {
    method: 'GET',
    credentials: 'include', // only good for clientside
    headers: {
      Cookie: cookieObjectToString(cookies) // only good for serverside
    },
    signal: abortController?.signal
  };

  return setSessionRequestedHeaders(config);
};

/**
 * Get detail for a specific product
 */
export function productBundle(
  { url, siteId, subsiteId }: CloudCatalogOptions,
  {
    asin,
    stockId,
    styleId,
    productId,
    includeTsdImages = false,
    includeOosSizing = false,
    includeOos = false,
    entireProduct = true,
    includeBrand = true,
    includeSizing = true,
    includeStocksWithoutAsins = false
  }: ProductBundleOptions,
  fetcher = timedFetch('cloudCatalogProduct'),
  cookies = {},
  abortController?: AbortController
): Promise<Response<ProductBundleResponse>> {
  const qs = stringify({
    asin,
    autolink: 'brandProductName',
    entireProduct,
    includeBrand,
    includeImages: true,
    includeOos,
    includeOosSizing,
    includeSizing,
    includeTsdImages,
    includeStocksWithoutAsins,
    includes: (includeOos ? BASE_INCLUDES : IN_STOCK_INCLUDES).join(','),
    productId,
    siteId,
    subsiteId,
    stockId,
    styleId
  });

  const fetchOpts = makeFetchOptions(cookies, abortController);
  const productUrl = `${url}/v3/productBundle?${qs}`;
  return fetcher(productUrl, fetchOpts);
}

/**
 * Get a list of all brands
 */
export function brandList(
  { url, siteId }: CloudCatalogOptions,
  fetcher = timedFetch('cloudCatalogBrandList')
): Promise<Response<CloudCatalogBrand[]>> {
  return fetcher(`${url}/v1/brandList?siteId=${siteId}`);
}

import type { IToggle } from 'unleash-proxy-client';

import type { AppState } from 'types/app';
import { FEATURE_MAFIA_AKAMAI, FEATURE_MARTY_CLIENT_RENDER, FEATURE_PDP_ENHANCEMENTS } from 'constants/features';
import type { SignUpDrawerVariant, SignUpDrawerVariantSelector } from 'components/common/SignUpDrawer/SignUpDrawer.types';
import { DESKTOP_MAXIMIZED_MOBILE_MINIMIZED, DESKTOP_MOBILE_MAXIMIZED, DESKTOP_MOBILE_MINIMIZED } from 'constants/emailSignupDrawer';

export const selectUnleashToggle = (feature: string, state: AppState): IToggle | undefined =>
  (state.unleash?.toggles || []).find((t: IToggle) => t.name === feature);

export const selectABTestEnabled = (testName: string, state: AppState, treatmentValue = '1') =>
  selectUnleashVariantByPayload(testName, state) === treatmentValue;

export const selectIsFeatureMafiaAkamai = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MAFIA_AKAMAI, state)?.enabled);

export const selectIsFeatureMartyClientRender = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MARTY_CLIENT_RENDER, state)?.enabled);

export const selectUnleashVariantByPayload = (feature: string, state: AppState) => selectUnleashToggle(feature, state)?.variant.payload?.value;

export const selectIsEmailDrawerSignup = (feature: string, unleashList?: { toggles?: IToggle[] }): SignUpDrawerVariantSelector => {
  if (!unleashList?.toggles) {
    return { isEnabled: false, variantVersion: 'control' };
  }
  const toggle = unleashList.toggles.find((t: IToggle) => t.name === feature);

  const variantName = toggle?.variant.name;
  const acceptedVariant =
    variantName === DESKTOP_MAXIMIZED_MOBILE_MINIMIZED || variantName === DESKTOP_MOBILE_MAXIMIZED || variantName === DESKTOP_MOBILE_MINIMIZED;
  const variantVersion: SignUpDrawerVariant = acceptedVariant ? variantName : 'control';

  const isEnabled = Boolean(toggle?.enabled && acceptedVariant);

  return { isEnabled, variantVersion };
};

export const selectFeaturePdpEnhancements = (state: AppState) => {
  const variant = selectUnleashToggle(FEATURE_PDP_ENHANCEMENTS, state)?.variant?.name;

  return {
    isTreatmentEnhancementsEnabled: variant === 'enhancements',
    isTreatmentPricingEnabled: variant === 'pricing',
    isOneOfPdpEnhancementsTreatmentEnabled: variant === 'enhancements' || variant === 'pricing'
  };
};

import { stringify } from 'query-string';
import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';

import timedFetch from 'middleware/timedFetch';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { setSessionCookies } from 'actions/session';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import type { AppState } from 'types/app';
import type { Cookies } from 'types/cookies';
import type { JanusParams, Recos } from 'types/janus';
import { fetchOpts } from 'apis/mafia/common';

export function recommendationsSearch(
  url: string,
  credentials: Cookies = {},
  numberOfRecos = 25,
  slotName = 'zap-hp-vh',
  fetcher = timedFetch('recommendationsSearch')
) {
  const janusUrl = `${url}/recos/get?limit=${numberOfRecos}&widgets=${slotName}`;

  return fetcher(janusUrl, fetchOpts({}, credentials));
}

interface JanusFetchOpts {
  params: JanusParams;
  widgets: string;
  limit?: number | string;
  credentials?: any; // TODO ts type this with creds;
  dispatch: ThunkDispatch<AppState, void, AnyAction>;
  getState: () => AppState;
}

export function getJanusRecos(
  url: string,
  { params, widgets, limit = 5, credentials = {}, dispatch, getState }: JanusFetchOpts,
  fetcher = timedFetch('janusRecos')
): Promise<Recos> {
  const query = stringify(
    {
      widgets,
      limit,
      ...params
    },
    { encode: false }
  );
  return fetcher(
    `${url}/recos/get?${query}`,
    fetchOpts(
      {
        credentials: 'include'
      },
      credentials
    )
  )
    .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
    .then(fetchErrorMiddleware);
}

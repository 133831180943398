/**
 * List of URL protocols that are considered safe for redirects
 */
export const SAFE_URL_PROTOCOLS = ['http:', 'https:'];

/**
 * List of dangerous URL patterns that should be blocked in sanitized URLs
 */
export const DANGEROUS_URL_PATTERNS = [
  'javascript:',
  'data:',
  'vbscript:',
  'file:',
  'about:',
  'blob:',
  'ftp:',
  'ws:',
  'wss:',
  'mailto:',
  'tel:',
  'sms:',
  'onclick=',
  'onerror=',
  'onload=',
  'onmouseover='
];

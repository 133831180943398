import React, { useEffect } from 'react';

import { cn } from 'helpers/classnames';
import type { AirplaneButtonDimension, AirplaneCacheDimensionOption, AirplaneCacheUnitOption, SetItemSelection } from 'types/AirplaneCache';
import { track } from 'apis/amethyst';
import { evProductNoStyleSizes } from 'events/product';
import { capitalize } from 'helpers/index';
import useMartyContext from 'hooks/useMartyContext';
import SizeUnitTabs from 'components/productdetail/stylepicker/SizeUnitTabs';
import AirplaneFieldsetOptions from 'components/productdetail/stylepicker/AirplaneFieldsetOptions';
import type { LegendHeading } from 'components/productdetail/stylepicker/AirplaneSeatSizing';

import css from 'styles/components/productdetail/stylepicker/airplaneFieldset.scss';

interface SizingUnitPicker {
  options: AirplaneCacheUnitOption[];
  selectedOption: AirplaneCacheUnitOption;
  setItemSelection: SetItemSelection;
}

export interface AirplaneFieldsetProps {
  applyWarningStyles: boolean;
  fieldsetHasWarnings: boolean;
  isInStockFn?: (option: AirplaneCacheDimensionOption) => boolean;
  isSelectSizeTooltipVisible: boolean;
  isSelectedFn?: (option: AirplaneCacheDimensionOption) => boolean;
  legendId?: string;
  legendText: LegendHeading;
  options: AirplaneCacheDimensionOption[];
  pickDisplayedOptions?: (options: AirplaneCacheDimensionOption[]) => AirplaneCacheDimensionOption[];
  radioButtonName: string;
  setItemSelection: SetItemSelection;
  sizingUnitPicker?: SizingUnitPicker;
  typeKey: AirplaneButtonDimension;
  useNarrowOptionButtons?: boolean;
  namespace: string;
  styleId: string;
  isOneOfPdpEnhancementsTreatmentEnabled?: boolean;
}

/**
 * Creates the data-test-id for the gender, size, and width legends.
 *
 * Tags for people grepping the codebase:
 * airplaneSeatSizeSelectAgeGroupLegend, airplaneSeatSizeSelectAgeGroupLegendWarning,
 * airplaneSeatSizeSelectGenderLegend, airplaneSeatSizeSelectGenderLegendWarning,
 * airplaneSeatSizeSelectSingleShoeSideLegend, airplaneSeatSizeSelectSingleShoeSideLegendWarning,
 * airplaneSeatSizeSelectSizeLegend, airplaneSeatSizeSelectSizeLegendWarning,
 * airplaneSeatSizeSelectWidthLegend, airplaneSeatSizeSelectWidthLegendWarning,
 * airplaneSeatSizeSelectSizeRangeLegend
 */
export function makeLegendDataTestId(typeKey: AirplaneButtonDimension, isSelectSizeTooltipVisible: boolean) {
  const keyInTestId = capitalize(typeKey);
  if (isSelectSizeTooltipVisible) {
    return `airplaneSeatSizeSelect${keyInTestId}LegendWarning`;
  }
  return `airplaneSeatSizeSelect${keyInTestId}Legend`;
}

function makeSizingUnitPicker({ options, selectedOption, setItemSelection }: SizingUnitPicker, isOneOfPdpEnhancementsTreatmentEnabled: boolean) {
  return (
    <SizeUnitTabs
      onChange={option => {
        const syntheticChangeEvent = {
          target: { id: option?.id || options[0]?.id }
        } as React.ChangeEvent<HTMLInputElement>;
        setItemSelection(syntheticChangeEvent);
      }}
      options={options}
      selectedOption={selectedOption}
      isOneOfPdpEnhancementsTreatmentEnabled={isOneOfPdpEnhancementsTreatmentEnabled}
    />
  );
}

export default function AirplaneFieldset({
  applyWarningStyles,
  fieldsetHasWarnings,
  isInStockFn,
  isSelectedFn,
  isSelectSizeTooltipVisible,
  legendId,
  legendText,
  useNarrowOptionButtons,
  options,
  pickDisplayedOptions,
  radioButtonName,
  setItemSelection,
  sizingUnitPicker,
  typeKey,
  namespace,
  styleId,
  isOneOfPdpEnhancementsTreatmentEnabled
}: AirplaneFieldsetProps) {
  const { testId } = useMartyContext();

  const legendTestId = testId(makeLegendDataTestId(typeKey, isSelectSizeTooltipVisible));
  const legendClassName = cn(
    css.title,
    {
      [css.warning]: fieldsetHasWarnings && applyWarningStyles
    },
    'mb-2',
    { ['flex items-center justify-between w-full mb-4']: isOneOfPdpEnhancementsTreatmentEnabled }
  );

  const displayedOptions = pickDisplayedOptions ? pickDisplayedOptions(options) : options;

  useEffect(() => {
    if (displayedOptions.length === 0) {
      track(() => [evProductNoStyleSizes, { productId: namespace, styleId }]);
    }
  }, [styleId, displayedOptions.length]);

  return (
    <fieldset>
      <legend id={legendId} data-test-id={legendTestId} className={legendClassName}>
        <span className={css.sizeOptionsLegendText}>
          {legendText.prefix}
          {legendText.suffix && <span className="font-normal">{legendText.suffix}</span>}{' '}
        </span>
        {sizingUnitPicker && makeSizingUnitPicker(sizingUnitPicker, isOneOfPdpEnhancementsTreatmentEnabled ?? false)}
      </legend>
      <AirplaneFieldsetOptions
        displayedOptions={displayedOptions}
        isInStockFn={isInStockFn}
        isSelectedFn={isSelectedFn}
        radioButtonName={radioButtonName}
        setItemSelection={setItemSelection}
        typeKey={typeKey}
        useNarrowOptionButtons={useNarrowOptionButtons}
        namespace={namespace}
      />
    </fieldset>
  );
}
